import { entry as arrayLayoutRendererEntry } from './arrayLayoutRenderer';
import { entry as verticalLayoutRendererEntry } from './verticalLayoutRenderer';
import { entry as horizontalLayoutRendererEntry } from './horizontalLayoutRenderer';
import { entry as groupRendererEntry } from './groupLayoutRenderer';
import { entry as customDnsZonesLayoutRenderer } from "./customDnsZonesLayoutRenderer";
import { entry as customNetworkLayoutRenderer } from "./customNetworkLayoutRenderer";
import { entry as customVnetsLayoutRenderer } from "./customVnetsLayoutRenderer";
import { entry as customZonesLayoutRenderer } from "./customZonesLayoutRenderer";
import { entry as customOpassLayoutRenderer } from "./customOpassLayoutRenderer";

export {
  arrayLayoutRendererEntry,
  verticalLayoutRendererEntry,
  horizontalLayoutRendererEntry,
  groupRendererEntry,
  customDnsZonesLayoutRenderer,
  customNetworkLayoutRenderer,
  customVnetsLayoutRenderer,
  customZonesLayoutRenderer,
  customOpassLayoutRenderer,
};

export const layoutRenderers = [
  arrayLayoutRendererEntry,
  verticalLayoutRendererEntry,
  horizontalLayoutRendererEntry,
  groupRendererEntry,
  customDnsZonesLayoutRenderer,
  customNetworkLayoutRenderer,
  customVnetsLayoutRenderer,
  customZonesLayoutRenderer,
  customOpassLayoutRenderer,
];