
import {
  ControlElement,
} from '@jsonforms/core';
import {
  rendererProps,
  RendererProps,
  useJsonFormsOneOfEnumControl,
} from '@jsonforms/vue';
import { defineComponent } from 'vue';
import { useTranslator, useQuasarControl } from '../util';
import { default as ControlWrapper } from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'oneof-enum-control-renderer',
  components: {
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  computed: {
    fieldRules() {
      const rules = [];
      if (this.control.required) {
        rules.push((val: string) => !!val || 'Field is required');
      }
      if (this.control.schema.pattern) {
        const pattern = new RegExp(this.control.schema.pattern);
        rules.push((v: any) => pattern.test(v) || 'No valid value')
      }
      return rules;
    }
  },
  data() {
    return {
      disable: false,
    }
  },
  setup(props: RendererProps<ControlElement>) {
    const t = useTranslator();

    const control = useQuasarControl(
        useJsonFormsOneOfEnumControl(props),
        (value: any) => (value !== null ? value : undefined)
    );
    return { ...control, t };
  },
  mounted() {
    this.disable = this.control.data && this.control.data.length && this.control.uischema.options && !this.control.uischema.options.editable ? true : false
    this.onChange(this.control.data);
  },
});

export default controlRenderer;

