<template>
  <q-table
    :rows="dataTable"
    :columns="cols"
    flat
    class="table-info"
    hide-bottom
    :pagination="{
      rowsPerPage: 0
    }"
  >
  </q-table>
</template>

<script>
  export default {
    name: 'ServiceTable',
    props: {
      dataTable: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        cols: [],
      }
    },
    mounted() {
      const rawColumns = []
      Object.keys(this.dataTable[0]).forEach(item => {
        const newObj = {};
        newObj.name = item;
        newObj.label = item.replace(/[A-Z]/g, " $&");
        newObj.field = item;
        newObj.align = 'left';
        newObj.sortable = false;
        rawColumns.push(newObj);
      })
      this.cols = Object.freeze(rawColumns);
    }
  }
</script>

<style lang="scss">
  .table-info {
    border-bottom: 1px solid #dedfe4;

    tbody {
      td {
        padding: 0 5px;
      }
    }
    thead {

      tr {
        height: 25px !important;
      }

      th {
        padding: 0 5px;
        text-transform: uppercase;
        font-size: 10px;
        color: #a29a9a;
        letter-spacing: 0.5px;
      }
    }
  }
</style>