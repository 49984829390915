<template>
  <q-table
    flat
    class="table-info"
    hide-bottom
    :pagination="{
      rowsPerPage: 0
    }"
    :rows="incidents"
    :columns="columns"
  >
    <template v-slot:body-cell-shorDescription="props">
      <q-td :props="props">
        <span>{{props.row.shorDescription}}</span>
        <sup class="q-ml-xs">
          <q-icon name="info">
            <q-tooltip max-width="480px">{{props.row.summary}}</q-tooltip>
          </q-icon>
        </sup>
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <span class="mim-link" @click="goToMim(props.row.sysId)">Go to MIM</span>
      </q-td>
    </template>
  </q-table>
</template>

<script>
  export default {
    name: 'MajorIncidentsTable',
    props: {
      incidents: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        columns: [
          {
            name: 'mimRecordId',
            field: 'mimRecordId',
            label: 'MIM ID',
            align: 'left',
          },
          {
            name: 'classification',
            field: 'classification',
            label: 'MIM classification',
            align: 'left',
          },
          {
            name: 'shorDescription',
            field: 'shorDescription',
            label: 'Description',
            align: 'left',
          },
          {
            name: 'action',
            field: 'action',
            label: '',
            align: 'left',
          },
        ]
      }
    },
    methods: {
      goToMim(id) {
        window.open(`https://swissreesm.service-now.com/mim?id=mimsummary&table=x_swre_sr_major_in_mim&sys_id=${id}&view=sp`, '_blank')
      }
    }
  }
</script>

<style scoped lang="scss">
.mim-link {
  color: $blue-4;
  text-decoration: underline;
  cursor: pointer;
}
</style>