import gql from 'graphql-tag';
import apollo from "@/library/http/apollo";
import store from '@/store';
import HomePage from "@/pages/home/HomePage.vue";
import Services from "@/pages/services/Services.vue";
import Service from "@/pages/services/Service.vue";
import InfraService from "@/pages/infraService/InfraService.vue";
import Templates from "@/pages/templates/Templates.vue";
import DigitalServices from "@/pages/digitalServices/DigitalServices.vue";
import StandardsPage from "@/pages/standards/Standards.vue";
import DigitalService from "@/pages/digitalService/DigitalService.vue";
import ServiceStatus from "@/pages/serviceStatus/ServiceStatus.vue";
import Adrs from "@/pages/adrs/Adrs.vue";
import Adr from "@/pages/adrs/Adr.vue";
import NewInfraService from "@/pages/infraService/NewInfraService.vue";
import { getUserPermissions } from "@/pages/digitalService/api";

const routes: Array<any> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      layout: 'DefaultLayout'
    }
  },
  {
    path: "/standards",
    name: "standards",
    component: StandardsPage,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await store.dispatch('app/setPageTitle', 'Relevant Standards');
        return next();
      },
      layout: 'SinglePageLayout',
      title: 'Relevant Standards',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Relevant Standards'
        }
      ]
    }
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    meta: {
      layout: 'CatalogLayout',
      title: 'Service Catalog',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Service Catalog'
        }
      ]
    },
  },
  {
    path: "/my-portfolio",
    name: "digitalServices",
    component: DigitalServices,
    meta: {
      layout: 'MyPortfolioLayout',
      title: 'Digital Services',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'My Portfolio'
        }
      ]
    },
  },
  {
    path: "/my-portfolio/:id",
    name: "digitalService",
    component: DigitalService,
      meta: {
        beforeResolve: async (to: any, from: any, next: any) => {
          await apollo.apolloClient.query({
            query: gql`
              query {
                getApplicationByAPMID(digitalServiceAPMID: "${to.params.id.toUpperCase()}") {
                  apmId
                  digitalServiceName
                }
              }
            `
          }).then(async (response) => {
            const application = response.data.getApplicationByAPMID;
            await store.dispatch('app/setPageTitle', application.digitalServiceName || application.apmId);
          });
        return next();
        },
        layout: 'DigitalServiceLayout',
        breadcrumbs: [
          {
            text: 'Home',
            to: '/'
          },
          {
            to: '/my-portfolio',
            text: 'My Digital Services'
          },
        ]
      }
  },
  {
    path: "/services/:id",
    name: "service",
    component: Service,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await apollo.apolloClient.query({
          query: gql`
            query {
              categoryServicesById(category: "${to.params.id}") {
                name
              }
            }
          `
        }).then(async (response) => {
          const service = response.data.categoryServicesById;
          await store.dispatch('app/setPageTitle', service.name);
        });
        return next();
      },
      layout: 'ServiceLayout',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          to: '/services',
          text: 'Service Catalog'
        },
      ]
    }
  },
  {
    path: "/infra-services/:id",
    name: "infraService",
    component: InfraService,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await apollo.apolloClient.query({
          query: gql`
            query {
              infraServiceById(id: "${to.params.id}") {
                displayName
                serviceName
                owners
              }
            }
          `
        }).then(async (response) => {
          const service = response.data.infraServiceById;
          await store.dispatch('app/setPageTitle', service.displayName || service.serviceName);
          await store.dispatch('app/setInfraServiceOwners', service.owners || []);
        });
        return next();
      },
      layout: 'SinglePageLayout',
      infraService: true,
      title: 'Single Service Page',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Service Catalog',
          to: '/services'
        },
      ]
    },
  },
  {
    path: "/services/new",
    name: "newInfraService",
    component: NewInfraService,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        let isAuth = false;
        if (!to.params?.category) {
          await store.dispatch('app/setPageTitle', 'Create New Infrastructure Service');
          await apollo.apolloClient.query({
            query: gql`
            query { whoAmI
          }`
          }).then(async (response) => {
            const email = response.data.whoAmI;
            const authData = {
              "userEmail": email,
              "scope": {},
              "resource": {
                "type": "management",
                "name": "service.catalog.manage"
              }
            }
            isAuth = await getUserPermissions(authData);
          });
        } else {
          isAuth = true;
        }
        if (isAuth) {
          return next();
        }
        return next('/');
      },
      layout: 'NewInfraStructureServiceLayout',
      infraService: true,
      title: 'Create New Infrastructure Service',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Service Catalog',
          to: '/services'
        },
      ]
    },
  },
  {
    path: "/templates",
    name: "templates",
    component: Templates,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await store.dispatch('app/setPageTitle', 'Templates');
        return next();
      },
      layout: 'TemplatesLayout',
      title: 'Templates',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Templates'
        }
      ]
    },
  },
  {
    path: "/service-status",
    name: "serviceStatus",
    component: ServiceStatus,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await store.dispatch('app/setPageTitle', 'Service Status');
        return next();
      },
      layout: 'ServiceStatusLayout',
      title: 'Service Status',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Service Status'
        }
      ]
    },
  },
  {
    path: "/adrs",
    name: "adrs",
    component: Adrs,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await store.dispatch('app/setPageTitle', 'ADRs');
        return next();
      },
      layout: 'AdrsLayout',
      title: 'ADRs',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'ADRs'
        }
      ]
    },
  },
  {
    path: "/adrs/:id",
    name: "adr",
    component: Adr,
    meta: {
      beforeResolve: async (to: any, from: any, next: any) => {
        await apollo.apolloClient.query({
          query: gql`
            query {
              getADRs(filter: {filterBy: {adrId: "${to.params.id}"}, includeFields: {}}) {
                adrs {
                  name
                }
              }
            }
          `
        }).then(async (response) => {
          const adr = response.data.getADRs.adrs;
          await store.dispatch('app/setPageTitle', adr[0].name);
        });
        return next();
      },
      layout: 'SinglePageLayout',
      breadcrumbs: [
        {
          text: 'Home',
          to: '/'
        },
        {
          to: '/adrs',
          text: 'ADRs'
        },
      ]
    }
  },
];

export default routes;