<template>
  <q-page padding class="main-wrapper-page single-page" style="background-color: var(--q-accent);">
    <div class="row no-wrap q-col-gutter-sm">
      <div class="col">
        <q-input @update:model-value="currentService = undefined" v-model="filters.digitalServiceName" label="Name or APMID" outlined dense clearable class="q-mb-sm bg-white" ></q-input>
      </div>
      <div class="col">
        <q-select @update:model-value="currentService = undefined" v-model="filters.digitalServiceType" label="Service type" :options="['Infrastructure', 'Application']" clearable options-dense outlined dense class="q-mb-sm bg-white"></q-select>
      </div>
      <div class="col">
        <q-select
          @update:model-value="currentService = undefined"
          v-model="filters.businessDomain"
          use-input
          input-debounce="0"
          label="Business domain" :options="businessDomainOptions" @filter="filterFn" clearable options-dense outlined dense class="business-domain-select q-mb-sm bg-white"
        >
          <template v-slot:selected>
            <span class="business-domain-value">{{filters.businessDomain}}</span>
          </template>
        </q-select>
      </div>
      <div class="col">
        <q-input @update:model-value="currentService = undefined" v-model="filters.owner" label="Owner (email)" outlined dense class="q-mb-sm bg-white" clearable></q-input>
      </div>
      <div class="col">
        <q-select
          @update:model-value="currentService = undefined"
          v-model="filters.itscmCriticality"
          label="ITSCM Criticality"
          clearable
          options-dense
          outlined
          dense
          class="q-mb-sm bg-white"
          :options="['0 - Highly Critical', '1 - Critical', '2 - High', '3 - Medium', '4 - Low']"
        ></q-select>
      </div>
      <div class="col-2">
        <q-toggle @update:model-value="currentService = undefined" :disable="!allUserApps.length" v-model="filters.onlyMy" label="Show only my"></q-toggle>
      </div>
    </div>
    <div class="row no-wrap q-col-gutter-lg">
      <div class="col col-4">
        <div v-if="serviceStatus.length">
          <q-scroll-area
            :thumb-style="{ borderRadius: '5px', width: '3px', background: '#9f9f9f', height: '3px', zIndex: 999999, opacity: 1 }"
            :style="`height: calc(100vh - ${currentBanner ? '240px' : '190px'})`"
          >
            <div v-if="filteredServices.filter(service => service.status === 'outage').length" class="bg-white q-px-md q-py-md q-mb-sm" style="border-radius: 15px">
              <div class="service-item flex justify-between items-center" v-for="(service, key) in filteredServices.filter(i => i.status === 'outage')" :ref="service.apmId" :key="key" @click.prevent="setCurrentService(service)">
                <div class="name flex no-wrap items-center">
                  <q-icon
                    size="20px"
                    class="q-mr-sm"
                    :name="service.status === 'warning' ? 'warning' : service.status === 'outage' ? 'error' : service.status === 'action' ? 'offline_pin' : service.status === 'info' ? 'info' : 'check_circle'"
                    :color="service.status === 'warning' ? 'orange-7' : service.status === 'outage' ? 'red-9' : service.status === 'action' ? 'green-6' : service.status === 'info' ? 'light-blue-13' : 'primary'"
                  ></q-icon>
                  <div class="title flex column">
                    <strong style="text-transform: uppercase; line-height: 1">{{service.apmId}}</strong>
                    <span style="font-size: 11px; color: #979ba4">{{service.digitalServiceName}}</span>
                  </div>
                </div>
                <div class="banners-count">
                  <div>
                    <span class="count-outage">{{service?.serviceStatuses.filter(i => i.type === 'outage' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-warning">{{service?.serviceStatuses.filter(i => i.type === 'warning' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-action">{{service?.serviceStatuses.filter(i => i.type === 'action' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-info">{{service?.serviceStatuses.filter(i => i.type === 'info' && i.enabled)?.length}}</span>
                  </div>
                  <div v-if="service.majorIncidents.length" class="major-incidents">Active major incidents: <b>{{service.majorIncidents.length}}</b></div>
                </div>
                <div class="hover">
                  <q-icon style="float: right; margin-top: 13px;" color="grey-6" name="navigate_next" size="25px" class="q-mr-xs"></q-icon>
                </div>
              </div>
            </div>
            <div v-if="filteredServices.filter(service => service.status === 'warning').length" class="bg-white q-px-md q-py-md q-mb-sm" style="border-radius: 15px">
              <div class="service-item flex justify-between items-center" v-for="(service, key) in filteredServices.filter(i => i.status === 'warning')" :ref="service.apmId" :key="key" @click.prevent="setCurrentService(service)">
                <div class="name flex items-center">
                  <q-icon
                      size="20px"
                      class="q-mr-sm"
                      :name="service.status === 'warning' ? 'warning' : service.status === 'outage' ? 'error' : service.status === 'action' ? 'offline_pin' : service.status === 'info' ? 'info' : 'check_circle'"
                      :color="service.status === 'warning' ? 'orange-7' : service.status === 'outage' ? 'red-9' : service.status === 'action' ? 'green-6' : service.status === 'info' ? 'light-blue-13' : 'primary'"
                  ></q-icon>
                  <div class="title flex column">
                    <strong style="text-transform: uppercase; line-height: 1">{{service.apmId}}</strong>
                    <span style="font-size: 11px; color: #979ba4">{{service.digitalServiceName}}</span>
                  </div>
                </div>
                <div class="banners-count">
                  <div>
                    <span class="count-outage">{{service?.serviceStatuses.filter(i => i.type === 'outage' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-warning">{{service?.serviceStatuses.filter(i => i.type === 'warning' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-action">{{service?.serviceStatuses.filter(i => i.type === 'action' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-info">{{service?.serviceStatuses.filter(i => i.type === 'info' && i.enabled)?.length}}</span>
                  </div>
                  <div v-if="service.majorIncidents.length" class="major-incidents">Active major incidents: <b>{{service.majorIncidents.length}}</b></div>
                </div>
                <div class="hover">
                  <q-icon style="float: right; margin-top: 13px;" color="grey-6" name="navigate_next" size="25px" class="q-mr-xs"></q-icon>
                </div>
              </div>
            </div>

            <div v-if="filteredServices.filter(service => service.status === 'action').length" class="bg-white q-px-md q-py-md q-mb-sm \" style="border-radius: 15px">
              <div class="service-item flex justify-between items-center" v-for="(service, key) in filteredServices.filter(i => i.status === 'action')" :ref="service.apmId" :key="key" @click.prevent="setCurrentService(service)">
                <div class="name flex items-center">
                  <q-icon
                      size="20px"
                      class="q-mr-sm"
                      :name="service.status === 'warning' ? 'warning' : service.status === 'outage' ? 'error' : service.status === 'action' ? 'offline_pin' : service.status === 'info' ? 'info' : 'check_circle'"
                      :color="service.status === 'warning' ? 'orange-7' : service.status === 'outage' ? 'red-9' : service.status === 'action' ? 'green-6' : service.status === 'info' ? 'light-blue-13' : 'primary'"
                  ></q-icon>
                  <div class="title flex column">
                    <strong style="text-transform: uppercase; line-height: 1">{{service.apmId}}</strong>
                    <span style="font-size: 11px; color: #979ba4">{{service.digitalServiceName}}</span>
                  </div>
                </div>
                <div class="banners-count">
                  <div>
                    <span class="count-outage">{{service?.serviceStatuses.filter(i => i.type === 'outage' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-warning">{{service?.serviceStatuses.filter(i => i.type === 'warning' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-action">{{service?.serviceStatuses.filter(i => i.type === 'action' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-info">{{service?.serviceStatuses.filter(i => i.type === 'info' && i.enabled)?.length}}</span>
                  </div>
                  <div v-if="service.majorIncidents.length" class="major-incidents">Active major incidents: <b>{{service.majorIncidents.length}}</b></div>
                </div>
                <div class="hover">
                  <q-icon style="float: right; margin-top: 13px;" color="grey-6" name="navigate_next" size="25px" class="q-mr-xs"></q-icon>
                </div>
              </div>
            </div>
            <div v-if="filteredServices.filter(service => service.status === 'info').length" class="bg-white q-px-md q-py-md q-mb-sm" style="border-radius: 15px">
              <div class="service-item flex justify-between items-center" v-for="(service, key) in filteredServices.filter(i => i.status === 'info')" :ref="service.apmId" :key="key" @click.prevent="setCurrentService(service)">
                <div class="name flex items-center">
                  <q-icon
                      size="20px"
                      class="q-mr-sm"
                      :name="service.status === 'warning' ? 'warning' : service.status === 'outage' ? 'error' : service.status === 'action' ? 'offline_pin' : service.status === 'info' ? 'info' : 'check_circle'"
                      :color="service.status === 'warning' ? 'orange-7' : service.status === 'outage' ? 'red-9' : service.status === 'action' ? 'green-6' : service.status === 'info' ? 'light-blue-13' : 'primary'"
                  ></q-icon>
                  <div class="title flex column">
                    <strong style="text-transform: uppercase; line-height: 1">{{service.apmId}}</strong>
                    <span style="font-size: 11px; color: #979ba4">{{service.digitalServiceName}}</span>
                  </div>
                </div>
                <div class="banners-count">
                  <div>
                    <span class="count-outage">{{service?.serviceStatuses.filter(i => i.type === 'outage' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-warning">{{service?.serviceStatuses.filter(i => i.type === 'warning' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-action">{{service?.serviceStatuses.filter(i => i.type === 'action' && i.enabled)?.length}}</span>
                    <span class="separator"></span>
                    <span class="count-info">{{service?.serviceStatuses.filter(i => i.type === 'info' && i.enabled)?.length}}</span>
                  </div>
                  <div v-if="service.majorIncidents.length" class="major-incidents">Active major incidents: <b>{{service.majorIncidents.length}}</b></div>
                </div>
                <div class="hover">
                  <q-icon style="float: right; margin-top: 13px;" color="grey-6" name="navigate_next" size="25px" class="q-mr-xs"></q-icon>
                </div>
              </div>
            </div>
            <div v-if="filteredServices.filter(service => service.status === 'available').length" class="bg-white q-px-md q-py-md" style="border-radius: 15px">
              <div class="service-item flex justify-between items-center" v-for="(service, key) in filteredServices.filter(i => i.status === 'available')" :ref="service.apmId" :key="key" @click.prevent="setCurrentService(service)">
                <div class="name flex items-center">
                  <q-icon
                      size="20px"
                      class="q-mr-sm"
                      :name="service.status === 'warning' ? 'warning' : service.status === 'outage' ? 'error' : service.status === 'action' ? 'offline_pin' : service.status === 'info' ? 'info' : 'check_circle'"
                      :color="service.status === 'warning' ? 'orange-7' : service.status === 'outage' ? 'red-9' : service.status === 'action' ? 'green-6' : service.status === 'info' ? 'light-blue-13' : 'primary'"
                  ></q-icon>
                  <div class="title flex column">
                    <strong style="text-transform: uppercase; line-height: 1">{{service.apmId}}</strong>
                    <span style="font-size: 11px; color: #979ba4">{{service.digitalServiceName}}</span>
                  </div>
                </div>
                <div v-if="service.majorIncidents.length" class="banners-count">
                  <div class="major-incidents">Active major incidents: <b>{{service.majorIncidents.length}}</b></div>
                </div>
                <div class="hover">
                  <q-icon style="float: right; margin-top: 13px;" color="grey-6" name="navigate_next" size="25px" class="q-mr-xs"></q-icon>
                </div>
              </div>
            </div>
          </q-scroll-area>
        </div>
      </div>
      <div class="col col-8">
        <div v-if="currentService">
          <div class="bg-white q-px-md q-py-md q-mb-md" style="border-radius: 15px">
            <div class="header flex items-center">
              <q-icon
                size="20px"
                class="q-mr-sm"
                :name="currentService.status === 'warning' ? 'warning' : currentService.status === 'outage' ? 'error' : 'check_circle'"
                :color="currentService.status === 'warning' ? 'orange-7' : currentService.status === 'outage' ? 'red-9' : 'primary'"
              ></q-icon>
              <strong style="text-transform: uppercase; cursor: pointer" @click="goToApplication(currentService.apmId.toUpperCase())">{{currentService.apmId}}</strong>
              <q-toggle class="q-ml-auto" v-model="showHistory" @update:model-value="changeShowHistory" color="primary">
                <q-tooltip>Show history</q-tooltip>
              </q-toggle>
            </div>
            <span class="date">{{ $options.dayjs(new Date()).format('DD.MM.YYYY HH:mm') }}</span>
            <div class="service-status-description">
              <p v-if="currentService.status === 'outage'">The service is experiencing outage or degradation.</p>
              <p v-else-if="currentService.status === 'warning'">The service might experience some degradation.</p>
              <p v-else-if="currentService.status === 'available' && currentService.serviceStatuses?.find(item => item.type === 'action')">The service is up and running with some actions required.</p>
              <p v-else>The service is up and running with no known issues.</p>
            </div>
            <div class="flex justify-between items-start">
              <table style="width: 250px;">
                <thead>
                <th>Info:</th>
                <th>Action:</th>
                <th>Warning:</th>
                <th>Outage:</th>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">{{currentService.serviceStatuses?.filter(item => item.type === 'info' && item.enabled).length}}</td>
                  <td class="text-center">{{currentService.serviceStatuses?.filter(item => item.type === 'action' && item.enabled).length}}</td>
                  <td class="text-center">{{currentService.serviceStatuses?.filter(item => item.type === 'warning' && item.enabled).length}}</td>
                  <td class="text-center">{{currentService.serviceStatuses?.filter(item => item.type === 'outage' && item.enabled).length}}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="currentService.majorIncidents.length">Active major incidents:
                <span
                  @click.stop="showMajorIncidents(currentService.majorIncidents)"
                  class="cursor-pointer text-bold"
                  style="color: darkred"
                >
                  {{currentService.majorIncidents.length}}
                  <q-tooltip>Show</q-tooltip>
                </span>
              </div>
            </div>
          </div>

          <div v-if="currentService.serviceStatuses?.length" class="statuses bg-white q-px-md q-py-md" style="border-radius: 15px">
            <q-scroll-area
              :thumb-style="{ borderRadius: '5px', width: '3px', background: '#9f9f9f', height: '3px', zIndex: 999999, opacity: 1 }"
              :style="`height: calc(100vh - ${currentBanner ? '470px' : '420px'})`"
            >
              <template v-for="(banner, key) in currentService.serviceStatuses" :key="key">
                <div class="status" v-if="banner.enabled || showHistory" :class="{history : !banner.enabled}">
                  <div class="status-date flex items-center">
                    <span class="date">{{$options.dayjs(banner.publishedAt).format('DD.MM.YYYY HH:mm')}}</span>
                    <q-icon v-if="!banner.enabled" name="hourglass_bottom" class="q-ml-sm"></q-icon>
                  </div>
                  <div class="flex items-center">
                    <q-icon
                      size="18px"
                      class="q-mr-xs"
                      :name="banner.type === 'warning' ? 'warning' : banner.type === 'outage' ? 'error' : banner.type === 'action' ? 'offline_pin' : banner.type === 'info' ? 'info' : 'check_circle'"
                      :color="banner.type === 'warning' ? 'orange-7' : banner.type === 'outage' ? 'red-9' : banner.type === 'action' ? 'green-6' : banner.type === 'info' ? 'light-blue-13' : 'primary'"
                    ></q-icon>
                    <h6 class="q-mt-none q-mb-none">{{banner.name}}</h6>
                  </div>
                  <div class="service-description-markdown q-mb-md" v-html="dataMarkdown(banner.content)"></div>
                </div>
              </template>
            </q-scroll-area>
          </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="showIncidentsDialog" class="single-service-page" persistent>
      <q-card style="min-width: 1024px; border-radius: 0px;">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Major Incidents</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="showIncidentsDialog = false"></q-btn>
        </q-toolbar>
        <q-card-section class="q-pt-none">
          <MajorIncidentsTable :incidents="majorIncidents"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
  import dayjs from "dayjs";
  import {marked} from "marked";
  import {mapActions, mapGetters} from "vuex";
  import {SERVICE_STATUS_ACTIONS, SERVICE_STATUS_GETTERS} from "@/store/modules/service-status";
  import {BANNERS_GETTERS} from "@/store/modules/banners";
  import apollo from "@/library/http/apollo";
  import gql from "graphql-tag";
  import MajorIncidentsTable from "@/pages/digitalServices/components/MajorIncidentsTable";

  export default {
    name: 'ServiceStatus',
    components: {
      MajorIncidentsTable,
    },
    dayjs,
    computed: {
      ...mapGetters('serviceStatus', {
        serviceStatus: SERVICE_STATUS_GETTERS.ALL,
      }),
      ...mapGetters('banners', {
        currentBanner: BANNERS_GETTERS.BANNERS_SHOW,
      }),

      filteredServices: function getFilteredBlackouts() {
        return Object.values(this.serviceStatus).filter((service) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.filters) {
            if (this.filters[prop]) {
              if (prop === 'onlyMy') {
               if (this.allUserApps.find(app => app.apmId.toLowerCase() === service.apmId.toLowerCase())) {
                 return true
               }
              }
              if (prop === 'digitalServiceName') {
                if (!(new RegExp(this.filters.digitalServiceName?.toLowerCase(), 'i').test(service.digitalServiceName.toLowerCase()) ||
                  new RegExp(this.filters.digitalServiceName?.toLowerCase(), 'i').test(service.apmId.toLowerCase()))) {
                  return false;
                }
              } else if (prop === 'owner') {
                if (!(new RegExp(this.filters.owner?.toLowerCase(), 'i').test(service.itOwnerEmail.toLowerCase()) ||
                  new RegExp(this.filters.owner?.toLowerCase(), 'i').test(service.businessOwnerEmail.toLowerCase()) ||
                  new RegExp(this.filters.owner?.toLowerCase(), 'i').test(service.businessOwnerCustodianEmail.toLowerCase()) ||
                  new RegExp(this.filters.owner?.toLowerCase(), 'i').test(service.itCustodianEmail.toLowerCase()))) {
                  return false;
                }
              } else if (prop === 'businessDomain') {
                if (this.filters.businessDomain !== service.businessDomain.name) {
                  return false;
                }
              } else {
                if (!new RegExp(this.filters[prop], 'i').test(service[prop])) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      },

    },
    methods: {
      ...mapActions('serviceStatus', {
        getServiceStatus: SERVICE_STATUS_ACTIONS.GET_SERVICE_STATUS
      }),
      showMajorIncidents(items) {
        this.majorIncidents = items;
        this.showIncidentsDialog = true;
      },
      setCurrentService(service, hideHistory = true) {
        this.currentService = service;
        this.showHistory = !hideHistory;
        Object.values(this.$refs).forEach(item => {
          item[0].classList.remove('active')
        })
        this.$refs[service.apmId][0].classList.add('active');
        this.changeShowHistory();
      },
      changeShowHistory() {
        if (this.showHistory) {
          this.$router.replace({ query: { infraservice: this.currentService.apmId, showHistory: true } });
        } else {
          this.$router.replace({ query: { infraservice: this.currentService.apmId } });
        }
      },
      dataMarkdown(data) {
        return marked(data)
      },
      goToApplication(apmId) {
        this.$router.push(`/my-portfolio/${apmId}`);
      },
      filterFn (val, update) {
        if (val === '') {
          update(() => {
            this.businessDomainOptions = this.businessDomains;
          })
          return;
        }
        update(() => {
          const needle = val.toLowerCase();
          this.businessDomainOptions = this.businessDomains.filter(v => v.toLowerCase().indexOf(needle) > -1);
        })
      }
    },
    data() {
      return {
        majorIncidents: [],
        showIncidentsDialog: false,
        allUserApps: [],
        currentService: undefined,
        showHistory: false,
        filterService: undefined,
        businessDomains: [],
        businessDomainOptions: [],
        filters: {
          digitalServiceName: undefined,
          digitalServiceType: undefined,
          owner: undefined,
          businessDomain: undefined,
          itscmCriticality: undefined,
          onlyMy: false,
        }
      }
    },
    async mounted() {
      await apollo.apolloClient.query({
        query: gql`
            query {
              getAllUserApplications {
                  apmId
                  digitalServiceName
              }
            }
          `
      }).then(res => {
        this.allUserApps = res.data.getAllUserApplications;
      })

      await apollo.apolloClient.query({
        query: gql`
          query {
            getBusinessDomains {
              name
            }
          }
        `
      }).then(res => {
       this.businessDomains = res.data.getBusinessDomains.map(i => i.name);
       this.businessDomainOptions = this.businessDomains;
      });
      if  (!this.serviceStatus.length) {
        this.$showLoading();
        try {
          await this.getServiceStatus();
        } catch (error) {
          this.$notify('negative', 'Error', error.message)
        } finally {
          this.$hideLoading();
        }
      }
      if (this.$route.query.infraservice) {
        const service = this.serviceStatus.find(item => item.apmId === this.$route.query.infraservice);
        this.setCurrentService(service, this.$route.query.showHistory ? false : true);
      }
      if (this.$route.query.showHistory) {
        this.showHistory = true;
      }
      setInterval(() => {
        this.getServiceStatus();
      }, 30000);
    }
  }
</script>

<style scoped lang="scss">
  .service-item {
    text-align: left;
    width: 100%;
    margin-left: auto;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 8px;
    border-bottom: 1px solid $grey-4;
    cursor: pointer;
    position: relative;

    .banners-count {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .major-incidents {
      font-size: 12px;
      b {
        color: darkred;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .close {
      opacity: 0;
    }

    &.active {
      background-color: var(--q-accent);

      .close {
        opacity: 1;
      }

      .hover {
        opacity: 0 !important;
      }
    }

    .hover {
      position: absolute;
      width: 100%;
      right: 0;
      left: -100%;
      opacity: 0;
      top: 0;
      bottom: 0;
      transition: background-color 0.6s ease-in-out, left 0.4s ease-in-out;
    }

    &:hover {
      .hover {
        opacity: 1;
        z-index: 1;
        left: 0;
        background-color: var(--q-accent);
      }
    }
  }
  .date {
    display: block;
    font-size: 12px;
    color: $grey-5;
  }
  .status {
    border-bottom: 1px solid $grey-4;
    margin-bottom: 10px;

    &.history {
      opacity: 0.5;
    }
  }
  .name {
    position: relative;
    z-index: 2;
  }

  .statuses {
    .status:last-child {
      border-bottom: none;
    }
  }

  .service-status-description {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
    }
  }

  .service-description-markdown {
    max-width: 100%;
    font-size: 14px;
  }
  .count-outage {
    color: $red-9;
    font-weight: 500;
  }
  .count-warning {
    color: $orange-7;
    font-weight: 500;
  }
  .count-action {
    color: $green-6;
    font-weight: 500;
  }
  .count-info {
    color: $light-blue-13;
    font-weight: 500;
  }
  .separator {
    height: 10px;
    width: 2px;
    background: #c2c2c2;
    display: inline-block;
    margin: 0 4px;
  }
  .business-domain-value {
    display: block;
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

</style>