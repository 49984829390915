
import {
  Layout,
} from '@jsonforms/core';
import { defineComponent } from 'vue';
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsLayout,
  RendererProps,
} from '@jsonforms/vue';

const layoutRenderer = defineComponent({
  name: 'horizontal-layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<Layout>(),
    freezeManifest: {type: Object, default: () => ({})},
    networkCanBeChanged: {type: Boolean, default: true},
    isNew: {
      type: Boolean,
      default: () => false
    },
    isNewEl: {
      type: Boolean,
      default: () => false
    },
    dateCanBeEdit: {
      type: Boolean,
      default: () => false
    },
    dataElement: {type: Object},
    manifest: {type: Object},
    currentEnv: {type:String, default: () => ''},
    networkPermissions: {type: Object},
    cloudentePermissions: {type: Object},
    isNetwork: {
      type: Boolean,
      default: () => false
    },
    approvalGroupState: {type: String},
    dense: {type: Boolean, default: false},
    showFilters: {type: Boolean, default: false},
  },
  setup(props: RendererProps<Layout>) {
    return useJsonFormsLayout(props);
  },
});

export default layoutRenderer;
