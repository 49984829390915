<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <ul v-for="(group, key) in Object.keys(infraService.links)" :key="key" :style="`width:${100/Object.values(infraService.links).length}%`">
      <span>{{ group }}</span>
      <li v-for="(link) in infraService.links[group]" :key="link.id">
        <a :href="link.url" target="_blank">{{link.label}}</a>
      </li>
    </ul>
  </div>
  <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
  </div>
  <div v-else>
    <div v-if="manifest.environments && manifest.environments.length" class="environments q-pt-sm">
      <span class="label-block q-mb-sm">Environments:</span>
      <ul>
        <li class="q-mb-xs" v-for="(environment, key) in manifest.environments" :key="key">
          <q-icon name="check_circle" color="blue-5" size="18px"></q-icon>
          {{environment}}
        </li>
      </ul>
    </div>
    <div class="q-pt-lg q-mb-lg">
      <span class="label-block block text-weight-bold q-mb-xs">Additional registration information:</span>
      <q-table
        :rows="rows"
        :columns="cols"
        flat
        class="table-info"
        hide-bottom
      />
    </div>
  </div>
  <q-expansion-item class="actions-item">
    <template v-slot:header>
      <strong>List of actions</strong>
    </template>
    <div v-if="loadActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0;">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="actions.length">
      <actions-table @showActions="actionsDialog = true" :actions="actions"/>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0;">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0;">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0;">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>


  <q-dialog v-model="actionsDialog" class="single-service-page" persistent>
    <q-card style="min-width: 768px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Actions for {{infraService.displayName || infraService.serviceName}}</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="actionsDialog = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-pt-none">
        <actions-table showAll :actions="actions" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ActionsTable from "@/pages/digitalService/components/ActionsTable";
import AccessProductsTable from "@/pages/digitalService/components/AccessProductsTable.vue";

export default {
  name: 'DbaasWidget',
  components: {
    AccessProductsTable,
    ActionsTable
  },
  props: {
    manifest: {type:Object},
    actions: {type:Array},
    infraService: {type:Object},
    accessProducts: {type:Object},
    loadManifest: {type:Boolean, default: () => false},
    loadActions: {type:Boolean, default: () => false},
    loadAccessProducts: {type:Boolean, default: () => false}
  },
  computed: {
    rows() {
      return [{
        businessArea: this.manifest.businessArea,
        dataClassification: this.manifest.dataClassification,
        itDomain: this.manifest.itDomain,
      }]
    }
  },
  data() {
    return {
      actionsDialog: false,
      tab: 'info',
      cols: [
        {
          label: 'Business Area',
          name: 'businessArea',
          field: 'businessArea',
          align: 'left'
        },
        {
          label: 'Data Classification',
          name: 'dataClassification',
          field: 'dataClassification',
          align: 'left'
        },
        {
          label: 'IT Domain',
          name: 'itDomain',
          field: 'itDomain',
          align: 'left'
        },
      ]
    }
  },
}
</script>
