<template>
  <div v-if="service.infraServices.length" class="services-holder">
    <template v-for="infraService in service.infraServices" :key="infraService.id">
      <div v-if="infraService.isCatalogPart" class="service-holder">
        <infra-service :service="service" :design="design" :data-service="infraService" />
      </div>
    </template>
    <ul class="messages-holder" v-if="service.positioningMessages && service.positioningMessages.length">
      <li class="message" v-for="(item, key) in service.positioningMessages" :key="key">
        <span class="service-description-markdown" v-html="dataMarkdown(item.message)"></span>
      </li>
    </ul>
  </div>
  <div v-if="service.serviceCategories.length">
    <template v-for="(category) in service.serviceCategories" :key="category.id">
      <div v-if="category.infraServices.length" class="main-category-holder">
        <span class="label">{{category.name}}</span>
        <div v-if="category.infraServices" class="services-holder">
          <template v-for="infraService in category.infraServices">
            <div v-if="infraService.isCatalogPart" :key="infraService.id" class="service-holder">
              <infra-service :service="service" :design="design" :data-service="infraService" />
            </div>
          </template>
          <ul class="messages-holder" v-if="category.positioningMessages && category.positioningMessages.length">
            <li class="message" v-for="(item, key) in category.positioningMessages" :key="key">
              <span class="service-description-markdown" v-html="dataMarkdown(item.message)"></span>
            </li>
          </ul>
        </div>
        <div v-if="category.serviceCategories && category.serviceCategories.length" class="sub-categories-holder">
          <div v-for="(category) in category.serviceCategories" :key="category.id" class="sub-category-holder" :class="category.serviceCategories && category.serviceCategories.length === 0 ? 'last' : ''">
            <span class="label">{{category.name}}</span>
            <div v-if="category.infraServices" class="services-holder">
              <template v-for="infraService in category.infraServices">
                <div v-if="infraService.isCatalogPart" :key="infraService.id" class="service-holder">
                  <infra-service :service="service" :design="design" data-service="infraService" />
                </div>
              </template>
            </div>
            <ul class="messages-holder" v-if="category.positioningMessages && category.positioningMessages.length">
              <li class="message" v-for="(item, key) in category.positioningMessages" :key="key">
                <span class="service-description-markdown" v-html="dataMarkdown(item.message)"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import {marked} from "marked";
  import InfraService from "@/pages/services/components/InfraService";

  export default {
    name: "ServiceCategory",
    components: { InfraService },
    props: {
      service: {
        type: Object
      },
      design: {
        type: Array
      }
    },
    methods: {
      dataMarkdown(data) {
        return marked(data)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .messages-holder {
    padding: 0 20px;
    width: 100%;
    column-count: 2;
    margin-bottom: 5px;

    .service-description-markdown {
      display: block;
      max-width: 100%;
      font-size: 14px;
    }
  }
  .main-category-holder {
    position: relative;
    border-top: 2px solid var(--q-primary);
    border-bottom: 2px solid var(--q-primary);
    border-radius: 10px;
    margin: 10px 0;

      > .label {
        text-transform: uppercase;
      }
  }
  .sub-categories-holder {
    margin-bottom: -10px;
  }
  .sub-category-holder {
    position: relative;
    border-top: 1px solid var(--q-primary);
    border-bottom: 1px solid var(--q-primary);
    border-radius: 10px;
    margin: 10px 0;
  }
  .label {
    position: absolute;
    left: 0;
    top: -8px;
    display: block;
    padding: 0 10px;
    background-color: white;
    color: var(--q-primary);
    font-size: 10px;
    font-weight: bold;
    z-index: 5;
    letter-spacing: 1px;
  }
  .services-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -5px;
    padding: 10px 0;

    .service-holder {
      width: 25%;
      max-width: 25%;
      padding: 5px;

      @media (max-width: $breakpoint-md-max) {
        width: 33.33%;
        max-width: 33.33%;
      }

      @media (max-width: $breakpoint-sm-max) {
        width: 50%;
        max-width: 50%;
      }
    }
  }
</style>