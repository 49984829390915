<template>
  <q-page padding class="main-wrapper-page single-page" :class="{'design-open': isServiceDesignOpen}" style="background-color: var(--q-accent);">
    <div v-if="filterLoading" class="loading">
      <q-spinner-gears
        color="primary"
        size="6em"
      />
    </div>
    <div class="page-view">
      <q-btn @click="pageView = 'list'" :class="{active: pageView === 'list'}" icon="mdi-view-list" text-color="blue-grey-6" flat class="btn--no-hover" :ripple="false">
        <q-tooltip>List view</q-tooltip>
      </q-btn>
      <q-btn @click="pageView = 'grid'" :class="{active: pageView === 'grid'}" icon="mdi-view-module" text-color="blue-grey-6" flat class="btn--no-hover" :ripple="false">
        <q-tooltip>Grid view</q-tooltip>
      </q-btn>
    </div>
    <div class="filter-holder">
      <ul class="filter-legend" style="padding-top: 10px; top: -50px; border-top-left-radius: 10px;">
        <li style="padding: 0 15px; color: #35454a; opacity: 1;">Select one or more items to filter of the services</li>
      </ul>
      <ul class="filter-legend" style="padding-top: 20px">
        <li :class="{active : filters.serviceTypes.private}" @click="filterByType('private')">Private CLoud</li>
        <li :class="{active : filters.serviceTypes.public}" @click="filterByType('public')">Public Clouds</li>
        <li :class="{active : filters.serviceTypes.onPrem}" @click="filterByType('onPrem')">On Prem</li>
        <li :class="{active : filters.serviceTypes.tools}" @click="filterByType('tools')">Tools</li>
        <li :class="{active : filters.serviceTypes.independent}" @click="filterByType('independent')">Independent</li>
        <li :class="{active : filters.serviceTypes.notAvailable}" @click="filterByType('notAvailable')">N/A</li>
      </ul>
      <ul class="types" style="padding-top: 20px">
        <strong>Cloud Type</strong>
        <li :class="{active : filters.serviceTypes.private}" class="private" @click="filterByType('private')">
          <span><q-icon v-if="filters.serviceTypes.private" name="done" size="12px"></q-icon></span>
        </li>
        <li :class="{active : filters.serviceTypes.public}" class="public" @click="filterByType('public')">
          <span><q-icon v-if="filters.serviceTypes.public" name="done" size="12px"></q-icon></span>
        </li>
        <li :class="{active : filters.serviceTypes.onPrem}" class="onprem" @click="filterByType('onPrem')">
          <span><q-icon v-if="filters.serviceTypes.onPrem" name="done" size="12px"></q-icon></span>
        </li>
        <li :class="{active : filters.serviceTypes.tools}" class="tool" @click="filterByType('tools')">
          <span><q-icon v-if="filters.serviceTypes.tools" name="done" size="12px"></q-icon></span>
        </li>
        <li :class="{active : filters.serviceTypes.independent}" class="independent" @click="filterByType('independent')">
          <span><q-icon v-if="filters.serviceTypes.independent" name="done" size="12px"></q-icon></span>
        </li>
        <li :class="{active : filters.serviceTypes.notAvailable}" class="notAvailable" @click="filterByType('notAvailable')">
          <span><q-icon v-if="filters.serviceTypes.notAvailable" name="done" size="12px"></q-icon></span>
        </li>
      </ul>
      <ul class="filter-legend" style="padding-top: 20px">
        <li :class="{active : filters.attributes.sharedServices}" @click="filterByAttribute('sharedServices')">Shared services</li>
        <li :class="{active : filters.attributes.stratum}" @click="filterByAttribute('stratum')">Stratum</li>
        <li :class="{active : filters.attributes.certified}" @click="filterByAttribute('certified')">Cloud Infrastructure Template</li>
        <li :class="{active : filters.attributes.planned}" @click="filterByAttribute('planned')">Planned</li>
        <li :class="{active : filters.attributes.deprecated}" @click="filterByAttribute('deprecated')">Not Recommended</li>
      </ul>
      <ul class="types attributes" style="padding-top: 18px">
        <strong>Attributes</strong>
        <li :class="{active : filters.attributes.sharedServices}" @click="filterByAttribute('sharedServices')">
          <q-icon name="share" size="18px"/>
        </li>
        <li :class="{active : filters.attributes.stratum}" @click="filterByAttribute('stratum')">
          <q-icon name="engp:engp-ico-stratum" size="16px"/>
        </li>
        <li :class="{active : filters.attributes.certified}" @click="filterByAttribute('certified')">
          <q-icon name="engp:engp-ico-cert_product" size="16px"/>
        </li>
        <li :class="{active : filters.attributes.planned}" @click="filterByAttribute('planned')">
          <q-icon name="engp:engp-ico-planned" size="16px"/>
        </li>
        <li :class="{active : filters.attributes.deprecated}" @click="filterByAttribute('deprecated')">
          <q-icon name="engp:engp-ico-deprecated" size="16px"/>
        </li>
      </ul>
      <ul class="filter-legend" style="padding-top: 15px; border-bottom-left-radius: 10px;">
        <li @click="resetFilters" :class="{active : canReset}" style="font-size: 14px; color: #35454a; font-weight: bold;">Reset</li>
      </ul>
      <ul class="types" style="padding-top: 15px;">
        <li @click="resetFilters" :class="{active : canReset}">
          <q-icon name="replay" size="20px"/>
        </li>
      </ul>
    </div>
    <transition-group
      v-if="catalogServices && catalogServices.length && pageView === 'grid'"
      appear
      enter-active-class="animated fadeIn"
    >
      <template
        v-for="(service, key) in catalogServices"
        :key="key"
      >
        <div
          v-if="(service.infraServices && service.infraServices.length && (service.infraServices.filter(infra => infra.isCatalogPart)).length) || hasInfraServices(service)"
          class="service-holder q-mb-lg q-px-md q-py-md bg-white">
          <div class="heading-service flex justify-between content-center">
            <q-toolbar-title :ref="service.categoryId" class="q-my-none text-weight-bold">{{service.name}}
              <sup v-if="service.description">
                <q-btn
                  push
                  flat
                  round
                  size="sm"
                  color="white"
                  text-color="text-color"
                  class="q-px-none"
                  icon="mdi-information-outline"
                >
                  <q-popup-proxy>
                    <q-banner class="service-description service-reasons" v-html="$options.marked(service.description)">
                    </q-banner>
                  </q-popup-proxy>
                </q-btn>
              </sup>
            </q-toolbar-title>
            <div class="btn-group">
              <q-btn @click="goToService(service, 'tree')" :disable="!hasWizard(service) || canReset" class="service-action-btn" icon="account_tree" outline round size="12px" v-ripple="{ color: 'primary' }">
                <q-tooltip>Decision Tree</q-tooltip>
              </q-btn>
              <q-btn v-if="isAuth" @click="goToNewService(service)" class="service-action-btn" icon="add" outline round size="12px" v-ripple="{ color: 'primary' }">
                <q-tooltip>Add Infrastructure Service</q-tooltip>
              </q-btn>
            </div>
          </div>
          <service-category :service="service" :design="design"></service-category>
        </div>
      </template>
    </transition-group>
    <div v-if="catalogServices && catalogServices.length && pageView === 'list'">
      <div class="row q-col-gutter-sm q-mb-sm">
        <div class="col">
          <q-input dense outlined bg-color="white" v-model="tableFilters.displayName" clearable label="Display name"></q-input>
        </div>
        <div class="col">
          <q-input dense outlined bg-color="white" v-model="tableFilters.apmid" clearable label="APMID"></q-input>
        </div>
        <div class="col">
          <q-select dense outlined bg-color="white" :options="categories" options-dense clearable v-model="tableFilters.categoryName" label="Category"></q-select>
        </div>
      </div>
      <q-table
      :rows="filteredInfraServices"
      :columns="cols"
      flat
      class="digital-service-table infra-table"
      hide-bottom
      :pagination="{
      rowsPerPage: 0
    }"
    >
      <template v-slot:body-cell-cloudType="props">
        <td>
          <span>{{props.row.cloudType || 'n/a'}}</span>
        </td>
      </template>
      <template v-slot:body-cell-attributes="props">
        <td>
          <q-icon v-if="props.row.type === 'Shared Service'" name="share" size="14px"></q-icon>
          <q-icon v-if="props.row.type === 'Stratum'" name="engp:engp-ico-stratum" size="14px" class="q-ml-xs"/>
          <q-icon v-if="props.row.type === 'Certified Product'" name="engp:engp-ico-cert_product" size="14px" class="q-ml-xs"/>
          <q-icon v-if="props.row.state === 'Planned'" name="engp:engp-ico-planned" size="14px" class="q-ml-xs"/>
          <q-icon v-if="props.row.state === 'Deprecated'" name="engp:engp-ico-deprecated" size="14px" class="q-ml-xs"/>
        </td>
      </template>
      <template v-slot:body-cell-actions="props">
        <td>
          <q-btn v-if="notCanBeDeleted(props.row)" class="btn--no-hover" size="12px" style="cursor: not-allowed" icon="lock" flat padding="0" :ripple="false">
            <q-tooltip>Can not be removed from service design</q-tooltip>
          </q-btn>
          <span v-else>
          <q-btn
            v-if="props.row.orderable ? !isInDesign(props.row) : false"
            @click="addToDesign(props.row)"
            class="btn--no-hover"
            :icon="isInDesign(props.row) ? 'check' : 'add'"
            flat padding="0"
            :ripple="false"
          >
            <q-tooltip v-if="!isInDesign(props.row)">Add to Service Design</q-tooltip>
          </q-btn>
          <q-btn v-if="props.row.orderable ? isInDesign(props.row) : false" @click="removeFromServiceDesign(props.row)" class="btn--no-hover" icon="remove" flat padding="0" :ripple="false">
            <q-tooltip>Remove from Service Design</q-tooltip>
          </q-btn>
        </span>
          <q-btn @click="goToInfraService(props.row)" class="btn--no-hover" icon="more_horiz" flat padding="0" :ripple="false">
            <q-tooltip>More Info</q-tooltip>
          </q-btn>
        </td>
      </template>
    </q-table>
    </div>
  </q-page>
</template>

<script>
  import {marked} from "marked";
  import { mapGetters, mapActions } from "vuex";
  import ServiceCategory from "@/pages/services/components/ServiceCategory.vue";
  import {SERVICES_ACTIONS, SERVICES_GETTERS} from "@/store/modules/services";
  import emitter from "tiny-emitter/instance";
  import { scroll } from 'quasar';
  import { SERVICE_DESIGN_ACTIONS, SERVICE_DESIGN_GETTERS } from "@/store/modules/service-design";
  import {BANNERS_GETTERS} from "@/store/modules/banners";
  import { APP_ACTIONS, APP_GETTERS } from "@/store/modules/app";
  import { getUserPermissions } from "@/pages/digitalService/api";

  const { getScrollTarget, setVerticalScrollPosition } = scroll;

  export default {
    name: "ServiceCatalog",
    components: {ServiceCategory},
    marked,
    data() {
      return {
        isServiceDesignOpen: false,
        isAuth: false,
        initLoad: false,
        title: this.$route.meta.title,
        breadcrumbs: [],
        filterLoading: false,
        filters: {
          attributes: {
            sharedServices: false,
            stratum: false,
            certified: false,
            planned: false,
            deprecated: false,
          },
          serviceTypes: {
            public: false,
            private: false,
            onPrem: false,
            tools: false,
            independent: false,
            notAvailable: false,
          },
          pavedRoad: false
        },
        pageView: 'grid',
        tableFilters: {
          displayName: undefined,
          apmid: undefined,
          categoryName: undefined,
        },
        cols: [
          {
            label: 'Name',
            name: 'displayName',
            field: 'displayName',
            align: 'left',
            sortable: true,
          },
          {
            label: 'APMID',
            name: 'apmid',
            field: 'apmid',
            align: 'left',
            sortable: true,
          },
          {
            label: 'Category',
            name: 'categoryName',
            field: 'categoryName',
            align: 'left',
            sortable: true,
          },
          {
            label: 'Cloud type',
            name: 'cloudType',
            field: 'cloudType',
            align: 'left',
            sortable: true,
          },
          {
            label: 'Attributes',
            name: 'attributes',
            field: 'attributes',
            align: 'left'
          },
          {
            label: '',
            name: 'actions',
            field: 'actions',
            align: 'left'
          },
        ]
      }
    },
    created() {
      emitter.on('isServiceDesignOpen', (flag) => {
        this.isServiceDesignOpen = flag;
      });
      emitter.on('goToCategory', (apmId) => {
        const element = this.$refs[apmId][0].$el;
        const target = getScrollTarget(element);
        const offset = element.offsetTop - (this.currentBanner ? 95 : 45);
        const duration = 1000;
        setVerticalScrollPosition(target, offset, duration);
      })
    },

    async mounted() {
      if (!this.catalogServices.length) {
        await this.load();
      }
      const authData = {
        "userEmail": this.userInfo.email,
        "scope": {},
        "resource": {
          "type": "management",
          "name": "service.catalog.manage"
        }
      }
      this.isAuth = await getUserPermissions(authData);
      this.initLoad = true;
      this.breadcrumbs = this.$route.meta.breadcrumbs;
    },
    unmounted() {
      emitter.off('goToCategory');
    },
    computed: {
      ...mapGetters('services', {
        catalogServices: SERVICES_GETTERS.ALL,
        wizardCategories: SERVICES_GETTERS.WIZARD_CATEGORIES,
        catalogCategories: SERVICES_GETTERS.CATEGORIES,
      }),
      ...mapGetters('serviceDesign', {
        design: SERVICE_DESIGN_GETTERS.ALL
      }),
      ...mapGetters('banners', {
        currentBanner: BANNERS_GETTERS.CURRENT
      }),
      ...mapGetters('app', {
        userInfo: APP_GETTERS.USER_INFO,
      }),
      canReset() {
        return Object.values(this.filters.attributes).includes(true) || Object.values(this.filters.serviceTypes).includes(true) || this.filters.pavedRoad;
      },
      categories() {
        return this.catalogCategories.map(i => i.name);
      },
      filteredInfraServices() {
        return Object.values(this.allInfraServices).filter((service) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.tableFilters) {
            if (this.tableFilters[prop]) {
              if (!new RegExp(this.tableFilters[prop], 'i').test(service[prop])) {
                return false;
              }
            }
          }
          return true;
        });
      },
      allInfraServices() {
        const allInfraServices = [];
        this.catalogServices.forEach(category => {
          if (category?.infraServices.length) {
            category.infraServices.forEach(infra => {
              infra.categoryName = category.name;
              infra.categoryId = category.categoryId;
              allInfraServices.push(infra)
            })
          }
          if (category?.serviceCategories.length) {
            category.serviceCategories.forEach(subCategory => {
              if (subCategory.infraServices.length) {
                subCategory.infraServices.forEach(infra => {
                  infra.categoryName = category.name + '. ' + subCategory.name;
                  infra.categoryId = category.categoryId;
                  allInfraServices.push(infra)
                })
              }
            })
          }
        });
        return allInfraServices;
      }
    },
    methods: {
      ...mapActions('services', {
        getCatalog: SERVICES_ACTIONS.GET_SERVICES,
      }),
      ...mapActions('app', {
        setEditGroup: APP_ACTIONS.SET_INFRA_SERVICE_EDIT_GROUP,
      }),
      ...mapActions('serviceDesign', {
        setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
        removeInfraService: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE,
      }),
      notCanBeDeleted(service) {
        const category = this.design.find(category => category.categoryId === service.categoryId);
        return (category.infraServices.some(infra => infra.apmid === service.apmid && infra.notCanBeDeleted));
      },
      isInDesign(service) {
        const category = this.design.find(category => category.categoryId === service.categoryId);
        return category ? category.infraServices.some(infra => infra.apmid === service.apmid) : false;
      },
      addToDesign(service) {
        this.setInfraService({category: {categoryId: service.categoryId}, infraService: service});
        this.$notify('info', 'Info', `${service.displayName || service.serviceName} was added to your service design. Please choose digital service if you would like to onboard onto it.`);
      },
      removeFromServiceDesign(service) {
        this.removeInfraService({category: {categoryId: service.categoryId}, infraService: service});
        this.$notify('info', 'Info', `${this.dataService.displayName || this.dataService.serviceName} was removed from your service design`);
      },
      goToInfraService(service) {
        this.$router.push(`/infra-services/${service.id}`);
      },
      goToNewService(category) {
        this.$router.push({ name: 'newInfraService', params: { category: category.categoryId } });
      },
      hasInfraServices(service) {
        return service.serviceCategories && service.serviceCategories.length && !!((service.serviceCategories.filter(category => category.infraServices.length)).length)
      },
      filterByType(type) {
        this.filters.serviceTypes[type] = !this.filters.serviceTypes[type];
        this.filterServices();
      },
      filterByAttribute(attr) {
        this.filters.attributes[attr] = !this.filters.attributes[attr];
        this.filterServices();
      },
      resetFilters() {
        this.filters.attributes = {
          stratum: false,
          certified: false,
          planned: false,
          deprecated: false,
        };
        this.filters.serviceTypes = {
          public: false,
          private: false,
          onPrem: false,
          tools: false,
          independent: false,
          notAvailable: false,
        };
        this.filters.pavedRoad = false;
        this.filterServices();
      },
      async filterServices() {
        this.filterLoading = true;
        try {
          await this.getCatalog(this.filters);
        } catch (error) {
          this.$notify('negative', 'Error', error.message);
        } finally {
          this.filterLoading = false;
        }
      },
      hasWizard(service) {
        return this.wizardCategories.includes(service.categoryId)
      },
      goToService(service, view) {
        this.$router.push({
          name: 'service',
          params: { id: service.categoryId, pageView: view }
        });
      },
      async load() {
        this.$showLoading();
        try {
          await this.getCatalog(this.filters);
        } catch (error) {
          this.$notify('negative', 'Error', error.message);
        } finally {
          this.$hideLoading();
          this.initLoad = true;
        }
      },
    }
  };
</script>

<style lang="scss">
  .infra-table {
    border-radius: 8px;
  }
  .service-holder {
    border-radius: 15px;
  }
  .start-btn {
    font-size: 30px;
    text-transform: capitalize !important;
  }
  .btn-group {
    margin: 0 -3px;
  }
  .service-action-btn {
    margin: 0 3px;
  }
  .service-action-btn:before {
    border: 2px solid var(--q-primary) !important;
  }
  .service-description {
    max-width: 480px;
  }
  .filter-holder {
    position: fixed;
    right: 20px;
    top: 30%;
    z-index: 999;

    .types {
      position: relative;

      strong {
        position: absolute;
        font-size: 10px;
        text-transform: uppercase;
        color: #a5a5a5;
        display: block;
        right: -185px;
        top: -2px;
        background-color: white;
        padding: 0 5px;
        min-width: 70px;
        transition: all 0.5s;
      }

      &:before {
        content: '';
        width: 20px;
        height: 1px;
        background-color: #dddbdb;
        position: absolute;
        top: 5px;
        right: 0;
        transition: all 0.5s;
      }
    }

    &:hover {

      .types {
        &:before {
          width: 140px;
        }

        strong {
          right: 0;
        }
      }

      .filter-legend {
        right: -20px;
      }
    }

    .attributes {
      li {
        margin-bottom: 6px;
      }
    }

    .filter-legend {
      position: absolute;
      width: 225px;
      background-color: white;
      right: -250px;
      z-index: -1;
      transition: all 0.5s;
      box-shadow: 0px 20px 40px #3C415026;

      li {
        font-size: 12px;
        text-align: right;
        padding-right: 60px;
        cursor: pointer;
        color: $text-color;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 10px;
        cursor: pointer;
        opacity: 0.5;

        &.active {
          opacity: 1;
          font-weight: bold;
        }

        &.private {
          span {
            background-color: #e9f4ff;
            border-color: #428ce1;
          }
        }

        &.public {
          span {
            background-color: #e3f9ed;
            border-color: #73e1a5;
          }
        }

        &.onprem {
          span {
            background-color: #eee6f8;
            border-color: #a88cd1;
          }
        }

        &.independent {
          span {
            background-color: #eff5c6;
            border-color: #b3bb7e;
          }
        }

        &.tool {
          span {
            background-color: #f4f0e6;
            border-color: #bfb9a7;
          }
        }
      }

      span {
        position: relative;
        display: block;
        min-width: 18px;
        width: 18px;
        min-height: 18px;
        border-radius: 50%;
        border: 2px solid;

        i {
          position: absolute;
          left: 1px;
          top: 1px;
        }
      }
    }
  }
  .loading {
    position: fixed;
    left: 57px;
    top: 50px;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
  }
  .page-view {
    position: fixed;
    right: 0;
    top: 150px;
    display: flex;
    flex-direction: column;

    .q-btn {
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }
  .infra-table {
    .display-name-cell {
      display: flex;
      align-items: center;
      .icon-service {
        display: flex;
        min-width: 45px;
        height: 45px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
  .design-open {
    .page-view {
      right: 483px;
    }
    .filter-holder {
      right: 500px;
    }
  }
</style>