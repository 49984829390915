
import {
  ControlElement,
  composePaths,
  createDefaultValue,
  UISchemaElement,
  findUISchema,
  Resolve,
  JsonSchema,
  getFirstPrimitiveProp, getControlPath
} from "@jsonforms/core";
import { defineComponent, ref } from 'vue';
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsArrayControl,
  RendererProps,
} from '@jsonforms/vue';
import { useNested } from "../util";

const controlRenderer = defineComponent({
  name: 'custom-network-layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
    manifest: {type: Object, default: () => ({})},
    freezeManifest: {type: Object, default: () => ({})},
    networkPermissions: {type: Object},
    approvalGroupState: {type: String},
  },
  setup(props: RendererProps<ControlElement>) {
    const expanded = ref(0);
    const selectedEnv = ref<number>(0);
    const control = useJsonFormsArrayControl(props);
    const suggestToDelete = ref<null | number>(null);
    const showFilters = ref<boolean>(false);
    useNested('array');
    return {
      ...control,
      suggestToDelete,
      expanded,
      selectedEnv,
      showFilters
    };
  },
  computed: {
    addDisabled(): boolean {
      return (
        !this.control.enabled ||
        (
          this.dataLength >= 3
        ) ||
          this.approvalGroupState === 'APPROVAL_GROUP_INVALID'
      );
    },
    addEnvDisabled(): boolean {
      return (!this.control.enabled || this.dataLength >= 3)
    },
    dataLength(): number {
      return this.control.data ? this.control.data.length : 0;
    },
    foundUISchema(): UISchemaElement {
      return findUISchema(
        this.control.uischemas,
        this.control.schema,
        this.control.uischema.scope,
        this.control.path,
        undefined,
        this.control.uischema,
        this.control.rootSchema
      );
    },
    arraySchema(): JsonSchema | undefined {
      return Resolve.schema(
        this.control.rootSchema,
        this.control.uischema.scope,
        this.control.rootSchema
      );
    },
  },
  mounted() {
    if (this.freezeManifest?.environments?.length) {
      this.selectedEnv = 0;
    }
  },
  methods: {
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema)
      )();
      this.selectedEnv = this.control.data.length - 1;
    },
    childErrors(index: number) {
      return this.control.childErrors.filter((e) => {
        const errorDataPath = getControlPath(e);
        return errorDataPath.startsWith(
          this.composePaths(this.control.path, `${index}`)
        );
      });
    },
    removeItemsClick(event: Event, toDelete: number[]): void {
      event.stopPropagation();
      this.removeItems?.(this.control.path, toDelete)();
      this.selectedEnv = 0;
    },
    childLabelForIndex(index: number | null) {
      if (index === null) {
        return '';
      }
      const childLabelProp =
        this.control.uischema.options?.childLabelProp ??
        getFirstPrimitiveProp(this.control.schema);
      if (!childLabelProp) {
        return `${index}`;
      }
      const labelValue = Resolve.data(
        this.control.data,
        composePaths(`${index}`, childLabelProp)
      );
      if (
        labelValue === undefined ||
        labelValue === null ||
        Number.isNaN(labelValue)
      ) {
        return 'Choose environment';
      }
      return `${labelValue}`;
    },
    showFilter() {
      this.showFilters = !this.showFilters;
    },
    canBeDelete(element: any) {
      // TODO: Temporary solution
      if (element.name && element.region && element.size) {
        return this.manifest.environments.find((env: any) => env.vnets.find((vnet: any) => vnet === element));
      }

      if (this.control.uischema.options && this.control.uischema.options.detail.disabled) {
        const key: any = this.control.uischema.options && this.control.uischema.options.detail.key ? this.control.uischema.options.detail.key : null;
        const items = Object.keys(this.freezeManifest).length ? this.freezeManifest[key].map((item: any) => item.id) : [];
        return items.find((item: any) => item === element.id)
      }
      return false;
    }
  },
});

export default controlRenderer;
