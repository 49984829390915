import apollo from "@/library/http/apollo";
import gql from 'graphql-tag';

export const TEMPLATES_GETTERS = {
  ALL: 'templates',
};

export const TEMPLATES_ACTIONS = {
  GET_TEMPLATES: 'getTemplates',
  DELETE_TEMPLATE: 'deleteTemplate',
};

const TEMPLATES_MUTATIONS = {
  SET_TEMPLATES: 'setTemplates',
  DELETE_TEMPLATE: 'deleteTemplate',
};

export default {
  namespaced: true,
  state: {
    templates: []
  },
  getters: {
    [TEMPLATES_GETTERS.ALL]: (state: any) => {
      return state.templates;
    },
  },
  mutations: {
    [TEMPLATES_MUTATIONS.SET_TEMPLATES]: (state: any, templates: any) => {
      state.templates = templates;
    },
  },
  actions: {
    [TEMPLATES_ACTIONS.DELETE_TEMPLATE]: async (context: any, id: string) => {
      await apollo.apolloClient.mutate({
        mutation: gql`
          mutation removeTemplateFromUserView(
              $templateId: String!
          ) {
            removeTemplateFromUserView(
              templateId: $templateId
            )
          }
        `,
      variables: {
          templateId: id
        }
      });
    },
    [TEMPLATES_ACTIONS.GET_TEMPLATES]: async (context: any) => {
      await apollo.apolloClient.query({
        query: gql`
          query { templates {
            createdAt
            description
            name
            id
            templateViewers {
                email
            }
            type
            services {
              categoryId
              categoryName
              id
              apmid
              isCatalogPart
              orderable
              description
              displayName
              pavedRoad
              serviceName
              state
              cloudType
              type
              serviceId
              parentServiceId
              parentCategoryId
              parentCategoryName
            }
          }
        }
        `
      }).then(response => {
        const templates = response.data.templates;
        const newTemplates : any = [];
        templates.forEach((template: any) => {
          const newTemplate : any = {
            name: template.name,
            description: template.description,
            id: template.id,
            createdAt: template.createdAt,
            type: template.type,
            viewers: template.templateViewers?.length ? template.templateViewers.map((user: any) => user.email) : [],
            services: []
          }
          const categories : any = [];
          template.services?.forEach((service: any) => {
            if (!categories.find((item: any) => item.categoryId === service.parentCategoryId || item.categoryId === service.categoryId)) {
              categories.push({name: service.parentCategoryName || service.categoryName, categoryId: service.parentCategoryId || service.categoryId});
            }
          });
          categories.forEach((category:any) => {
            newTemplate.services.push({name: category.name, categoryId: category.categoryId, infraServices: []})
          })
          template.services?.forEach((service : any) => {
            newTemplate.services?.forEach((category : any) => {
              if (service.parentCategoryId === category.categoryId || service.categoryId === category.categoryId) {
                category.infraServices.push(service);
              }
            })
          })
          newTemplates.push(newTemplate)
        });
        context.commit(TEMPLATES_MUTATIONS.SET_TEMPLATES, newTemplates);
      })
    },
  },
}