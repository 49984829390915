<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent);">
    <q-form ref="form">
      <div class="row q-col-gutter-lg">
        <div class="col-4 new-ds-select" :class="{error : apmIdError}">
          <service-select
            :error="apmIdError"
            onlyActive
            fullWidth
            :label="formData.digitalService?.digitalServiceName ? formData.digitalService.digitalServiceName : 'Choose your APMID *'"
            @reset="resetService" @addService="setService($event)"
          >
            <template v-slot:hint>
              <span class="service-select-hint">It is not possible to create a new APM ID, please choose an existing one from the list. To create a new APM ID please use SNOW or <a href="https://dgf.swissre.com/" target="_blank">DGF</a></span>
            </template>
          </service-select>
        </div>
        <div class="col-4">
          <q-input
            v-model="formData.displayName"
            outlined
            :rules="[(val) => !!val || 'Field is required']"
            dense
            bg-color="white"
            label="Display name *"
          />
        </div>
        <div class="col-4">
          <q-select
            :disable="!parentInfraServices.length"
            option-value="value"
            map-options
            option-label="label"
            v-model="formData.parentInfraService"
            dense bg-color="white"
            options-dense
            emit-value
            outlined
            label="Parent infra service"
            :options="parentInfraServices"
          />
        </div>
        <div class="col-4">
          <q-select
            v-model="formData.category"
            option-value="value"
            :rules="[(val) => !!val || 'Field is required']"
            map-options
            option-label="label"
            dense bg-color="white"
            options-dense
            outlined
            label="Category *"
            emit-value
            :options="categories"
          />
        </div>
        <div class="col-4">
          <q-select
            :disable="!formData.category || !subCategories.length"
            option-value="value"
            map-options
            option-label="label"
            v-model="formData.subCategory"
            dense bg-color="white"
            options-dense
            emit-value
            outlined label="Sub category"
            :options="subCategories"
          />
        </div>
      </div>
      <div class="row q-col-gutter-lg">
        <div class="col-4">
          <q-select
            v-model="formData.cloudType"
            dense
            bg-color="white"
            outlined
            options-dense
            :rules="[(val) => !!val || 'Field is required']"
            label="Cloud type *"
            :options="cloudTypes"
          />
        </div>
      </div>
      <div class="row q-col-gutter-lg">
        <div class="col-4">
          <q-select
            v-model="formData.serviceType"
            dense
            options-dense
            bg-color="white"
            outlined
            :rules="[(val) => !!val || 'Field is required']"
            label="Service type *"
            :options="serviceTypes"
          />
        </div>
      </div>
      <div class="row q-col-gutter-lg">
        <div class="col-4">
          <q-select
            v-model="formData.state"
            dense
            options-dense
            bg-color="white"
            outlined
            label="Lifecycle stage *"
            :rules="[(val) => !!val || 'Field is required']"
            :options="lifecycleStages"
          />
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mt-xs">
        <div class="col-6">
          <q-scroll-area
            :style="{height: '185px'}"
            :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '2px', opacity: 1, right: 0, }"
          >
            <q-input type="textarea" ref="inputDescription" label="Description" bg-color="white" hint="Please, use markdown" v-model="formData.description" outlined></q-input>
          </q-scroll-area>
        </div>
        <div class="col-6">
          <q-scroll-area
            :style="{height: '185px'}"
            :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '2px', opacity: 1, right: 0, }"
          >
            <div class="single-page-markdown bg-white" v-html="dataMarkdown(formData.description)"></div>
          </q-scroll-area>
        </div>
      </div>
      <div class="row q-col-gutter-lg">
        <div class="col-6">
          <q-btn
            flat
            icon="add"
            color="white"
            text-color="black"
            small
            class="q-px-none q-mt-sm btn--no-hover q-mb-md"
            :ripple="false"
            size="11px"
            label="Add contact"
            @click="addContact"
          >
          </q-btn>
          <div class="row q-col-gutter-sm" v-for="(contact, key) in contacts" :key="key">
            <div class="col-5">
              <q-input dense bg-color="white" v-model="contacts[key].label" :rules="[(val) => !!val || 'Field is required', (val) => val.length < 40 || 'Max length is 40 symbols']" outlined label="Position"></q-input>
            </div>
            <div class="col-6">
              <q-input dense bg-color="white" v-model="contacts[key].contact" :rules="emailRules" outlined label="Email"></q-input>
            </div>
            <div class="col-1 text-center">
              <q-btn
                flat
                icon="remove"
                color="white"
                text-color="black"
                style="height: 40px"
                small
                class="q-px-none btn--no-hover"
                :ripple="false"
                size="11px"
                @click="removeContact(key)"
              >
                <q-tooltip>Remove contact</q-tooltip>
              </q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-xs">
        <div class="col-6">
          <q-btn
            flat
            icon="add"
            color="white"
            text-color="black"
            small
            class="q-px-none q-mt-sm btn--no-hover q-mb-md"
            :ripple="false"
            size="11px"
            label="Add link"
            @click="addLink"
          >
          </q-btn>
          <div class="row q-col-gutter-sm q-mb-sm" v-for="(link, key) in links" :key="key">
            <div class="col-5">
              <q-input dense bg-color="white" v-model="links[key].label" :rules="[(val) => !!val || 'Field is required', (val) => val.length < 40 || 'Max length is 40 symbols']" outlined label="Label"></q-input>
            </div>
            <div class="col-6">
              <q-input dense bg-color="white" v-model="links[key].url" :rules="[(val) => !!val || 'Field is required']" outlined label="URL"></q-input>
            </div>
            <div class="col-1 text-center">
              <q-btn
                flat
                icon="remove"
                color="white"
                text-color="black"
                small
                style="height: 40px"
                class="q-px-none btn--no-hover"
                :ripple="false"
                size="11px"
                @click="removeLink(key)"
              >
                <q-tooltip>Remove link</q-tooltip>
              </q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-md">
        <div class="col-6">
          <q-select
            label="How would you like to receive feedback"
            v-model="feedbackType"
            dense
            outlined
            options-dense
            bg-color="white"
            :options="['Teams', 'Email', 'SNOW']"/>
        </div>
        <div v-if="feedbackType === 'Teams'" class="col-6">
          <div class="row q-col-gutter-lg">
            <div class="col-6">
              <q-input v-model="feedback.channel" label="Channel name" bg-color="white" dense outlined bg-white/>
            </div>
            <div class="col-6">
              <q-input v-model="feedback.title" label="Message title" bg-color="white" dense outlined bg-white/>
            </div>
          </div>
        </div>
        <div v-if="feedbackType === 'Email'" class="col-6">
          <div class="row q-col-gutter-lg">
            <div class="col-6">
              <q-input v-model="feedback.email" label="Email" :rules="emailRules" bg-color="white" dense outlined bg-white/>
            </div>
            <div class="col-6">
              <q-input v-model="feedback.title" label="Message title" bg-color="white" dense outlined bg-white/>
            </div>
          </div>
        </div>
        <div v-if="feedbackType === 'SNOW'" class="col-6">
          <div class="row q-col-gutter-lg">
            <div class="col-6">
              <q-input v-model="feedback.group" label="Assignment Group" bg-color="white" dense outlined bg-white/>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <q-checkbox v-model="formData.toConsumeByDigitalService" label="Can be consumed by Digital Service (can Digital Service onboard to this service) "/>
        </div>
        <div class="col-12">
          <q-checkbox v-if="formData.toConsumeByDigitalService" v-model="isContact" label="Did you contact the Engineering Portal team in regards of onboarding implementation?" />
        </div>
        <div class="col-4 q-mx-sm" v-if="formData.toConsumeByDigitalService">
          <q-select
            v-model="formData.kind"
            dense
            bg-color="white"
            outlined
            options-dense
            :rules="[(val) => !!val || 'Field is required']"
            label="Kind *"
            :options="kinds"
          >
            <template v-slot:hint>
              <span>Please read the following Wiki article for details: <a href="https://wiki.swissre.com/pages/viewpage.action?pageId=775816223" target="_blank">What are the Kinds and how to use them - Platform Enabling Services - Confluence (swissre.com)</a></span>
            </template>
          </q-select>
        </div>
    </div>
    </q-form>
    <div class="row q-col-gutter-lg q-mt-lg">
      <div class="col-12">
        <q-btn :loading="load" :disable="load || (formData.toConsumeByDigitalService && !isContact)" @click="submit" color="primary" size="md">Submit</q-btn>
        <q-btn flat :ripple="false" @click="showConfirmDialog = true" class="btn--no-hover">Cancel</q-btn>
      </div>
    </div>
  </q-page>

  <q-dialog v-model="showConfirmDialog" class="single-service-page" persistent>
    <q-card style="min-width: 480px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Cancel</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showConfirmDialog = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <p>Are you sure? All changes will be lost.</p>
      </q-card-section>
      <q-card-actions class="q-px-md q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="$router.push('/services')">Yes</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showConfirmDialog = false">No</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
  import { marked } from "marked";
  import { v4 as uuidv4 } from 'uuid';
  import { mapActions, mapGetters } from "vuex";
  import { SERVICES_ACTIONS, SERVICES_GETTERS } from "@/store/modules/services";
  import ServiceSelect from "@/shared/components/ServiceSelect";
  import apollo from "@/library/http/apollo";
  import gql from "graphql-tag";

  export default {
   name: 'NewInfraService',
    components: {
     ServiceSelect
    },
    data() {
     return {
       showConfirmDialog: false,
       cloudTypes: [
         'Private',
         'Public',
         'Onprem',
         'N/A',
       ],
       serviceTypes: [
         'Shared Service',
         'Certified Product',
         'Stratum',
         'Vanilla',
         'Tool',
         'N/A',
       ],
       lifecycleStages: [
         'Deprecated',
         'Planned',
         'Production',
         'Unsupported',
       ],
       kinds: [
         'Registration3Env',
         'Registration2Env',
         'Registration1Env',
         'RegistrationCloudente',
         'RegistrationDBaaS',
         'RegistrationNetwork',
         'RegistrationMAS',
         'RegistrationOPASS',
       ],
       categories: [],
       contacts: [],
       links: [],
       isContact: false,
       feedbackType: undefined,
       feedback: {},
       apmIdError: false,
       load: false,
       formData: {
         digitalService: undefined,
         displayName: undefined,
         category: undefined,
         subCategory: undefined,
         parentInfraService: null,
         description: undefined,
         cloudType: undefined,
         serviceType: undefined,
         state: undefined,
         kind: null,
         toConsumeByDigitalService: false,
       }
     }
   },
   computed: {
     ...mapGetters('services', {
       catalog: SERVICES_GETTERS.ALL,
     }),
     emailRules() {
       const pattern = new RegExp("^\\S+@\\S+\\.\\S+$");
       return [
         (val) => !!val || 'Field is required',
         (val) => pattern.test(val) || 'Invalid email'
       ]
     },
     subCategories() {
       const category = this.catalog.find(cat => cat.categoryId === this.formData.category)
       if (category && category.serviceCategories.length) {
         return category.serviceCategories.map(cat => {
           const { name, categoryId } = cat;
           return { label: name, value: categoryId };
         });
       }
       return [];
     },

     parentInfraServices() {
       const infraServices = [{displayName: 'None', serviceId: 'none'}];
       const category = this.catalog.find(cat => cat.categoryId === this.formData.category);
       const allServices =  this.catalog.map(cat => cat.infraServices).flat();

       infraServices.push(...allServices);

       return infraServices
        .map(infra => ({label: infra.displayName, value: infra.serviceId}))
        .sort( (a, b) => {
          const {label: labelA} = a;
          const {label: labelB} = b;
          if (labelA < labelB) {
              return -1
          }
          if (labelA > labelB) {
              return 1
          }
          return 0
      });
     }
   },
   methods: {
     ...mapActions('services', {
       getCatalog: SERVICES_ACTIONS.GET_SERVICES,
     }),
     dataMarkdown(data) {
       return data ? marked(data) : '';
     },
     resetService() {
       this.formData.digitalService = undefined;
     },
     setService(service) {
       this.formData.digitalService = service;
       this.apmIdError = false;
     },
     addContact() {
       this.contacts.push({label: '', contact: ''})
     },
     removeContact(index) {
       this.contacts.splice(index, 1)
     },
     addLink() {
       this.links.push({label: '', url: '', description: '', id: uuidv4()})
     },
     removeLink(index) {
       this.links.splice(index, 1)
     },
     submit() {
       this.$refs.form.validate().then( async (valid) => {
         if (!this.formData.digitalService) {
           this.apmIdError = true
         } else {
           this.apmIdError = false;
         }
         if (valid) {
           this.load = true;
           const data = {
             apmid: this.formData.digitalService.apmId,
             displayName: this.formData.displayName,
             categoryId: this.formData.subCategory ? this.formData.subCategory : this.formData.category,
             parentService: this.formData.parentInfraService || null,
             cloudType: this.formData.cloudType,
             type: this.formData.serviceType,
             state: this.formData.state,
             description: this.formData.description || null,
             contacts: this.contacts.length ? this.contacts : null,
             links: this.links.length ? this.links : null,
             toConsumeByDigitalService: this.formData.toConsumeByDigitalService,
             kind: this.formData.kind
           }
           if (this.feedbackType === 'Teams') {
             data.feedback = {
               type: 'teams',
               channel: this.feedback.channel,
               title: this.feedback.title
             }
           } else if (this.feedbackType === 'Email') {
             data.feedback = {
               type: 'email',
               email: this.feedback.email,
               title: this.feedback.title
             }
           } else if (this.feedbackType === 'SNOW') {
             data.feedback = {
               type: 'snow',
               group: this.feedback.group,
             }
           } else {
             data.feedback = null;
           }
           await apollo.apolloClient.mutate({
             mutation: gql`
              mutation createNewInfraservice(
                  $infraService: InfraServiceCreateDTO!
              ) { createNewInfraservice( infraService: $infraService ) }
              `,
             variables: {
               infraService: data,
             }
           }).then(() => {
             this.$notify('positive', 'Success', 'Infrastructure service will be created.');
             this.$router.push('/services');
           }).catch((error) => {
             this.$notify('negative', 'Error', error.message);
           }).finally(() => {
             this.load = false;
           })
         }
       });
     }
   },
   async mounted() {
     if (!this.catalog.length) {
       await this.getCatalog();
     }
     this.categories = this.catalog.map(cat => {
       const { name, categoryId } = cat;
       return { label: name, value: categoryId };
     });
     if (this.$route.params?.category) {
       this.formData.category = this.$route.params.category;
     }
   }
  }
</script>

<style scoped>
  .exist-service-holder {
    background-color: #fff;
  }
  .single-page-markdown {
    background-color: white;
    min-height: 160px;
    padding: 15px;
    border-radius: 4px;
  }
  .service-select-hint {
    position: absolute;
    font-size: 11px;
    bottom: -30px;
    line-height: 1;
    color: #707072;
  }
</style>