<template>
  <q-page class="q-px-lg flex content-center landing-page">
    <div class="content-page-holder">
      <div class="row items-center">
        <div class="col col-md-9 col-lg-9 q-pr-lg">
          <h2 class="relative-position">
            <img class="absolute-top-right" style="top: -55px; width: 150px" :src="$options.gts" alt="">
            Welcome to<br>the Engineering Portal</h2>
          <div class="flex no-wrap items-center q-mb-md">
            <div class="icon-holder q-pr-sm">
              <q-icon name="engp:engp-ico-catalog" size="30px"></q-icon>
            </div>
            <p class="q-mb-none">
              You can find all services and tools needed for building your new application: starting from the basics, through the <router-link :to="{ name: 'services', params: { anchor: 'C_COMPUTE' }}">compute services</router-link>,
              <router-link :to="{ name: 'services', params: { anchor: 'C_NETWORK' }}">network services</router-link> to
              <router-link :to="{ name: 'services', params: { anchor: 'C_APPINT' }}">application integration services</router-link>,
              you will find all options to <router-link :to="{ name: 'services', params: { anchor: 'C_DATA' }}">store data</router-link> and all <router-link :to="{ name: 'services', params: { anchor: 'C_ANALYTICS' }}">DevOps tools</router-link> which you may need to start developing.<br>
              <a href="https://wiki.swissre.com/pages/viewpage.action?pageId=569100259" target="_blank">How to understand and use Service Catalogue?</a>
            </p>
          </div>
          <div class="flex no-wrap items-center q-mb-md">
            <div class="icon-holder q-pr-sm">
              <q-icon name="engp:engp-ico-templates" size="30px"></q-icon>
            </div>
            <p class="q-mb-none">
              Ready to implement or customized templates of the digital services.<br>
              <a href="https://wiki.swissre.com/display/CMP/How+to+create+and+use+Custom+Templates" target="_blank">How to create and use Custom Templates?</a><br>
              <a href="https://wiki.swissre.com/pages/viewpage.action?pageId=525680687" target="_blank">How to use Standart Templates?</a>
            </p>
          </div>
          <div class="flex no-wrap items-center q-mb-md">
            <div class="icon-holder q-pr-sm">
              <q-icon name="engp:engp-ico-digital_services" size="30px"></q-icon>
            </div>
            <p class="q-mb-none">
              The "My Portfolio" view contains a list of all digital services which you own. As owner, you can register application within
              a infrastructure services, create Cloudflare zones, Vnets and DNS records. It also allows to create all
              required platform roles and access products. It is also possible to observe any digital service of your interest<br>
              <a href="https://wiki.swissre.com/display/CMP/How+to+use+My+Portfolio" target="_blank">How to use My Portfolio?</a>
            </p>
          </div>
          <div class="flex no-wrap items-center q-mb-md">
            <div class="icon-holder q-pr-sm">
              <q-icon name="engp:engp-ico-service_status" size="30px"></q-icon>
            </div>
            <p class="q-mb-none">
              Service Status feature is giving the user a general overview of the statuses of all other digital and infrastructure services,
              if published by their onward, in one place.<br>
              <a href="https://wiki.swissre.com/display/CMP/How+to+understand+Service+Status" target="_blank">How to understand Service Status?</a>
            </p>
          </div>
          <div class="flex no-wrap items-center q-mb-md">
            <div class="icon-holder q-pr-sm">
              <q-icon name="engp:engp-ico-adrs_icon" size="30px"></q-icon>
            </div>
            <p class="q-mb-none">
              This page provides list of Architecture Decision Records published by the Enterprise Architecture team,
              and list of associated with them infrastructure services.<br>
              <a href="/adrs"  target="_blank">Architecture Decision Records (sharepoint.com)</a>
            </p>
          </div>
        </div>
        <div class="col col-md-3 col-lg-3">
          <div class="items-holder">
            <div class="item">
              <span class="title">
                <q-icon name="settings" size="sm"></q-icon>Services & Tools
              </span>
              <strong v-if="metrics.services">{{metrics.services || `Coming soon`}}</strong>
              <b v-else>Coming soon</b>
            </div>
            <div class="item">
              <span class="title">
                <q-icon name="mdi-view-grid" size="sm"></q-icon>Applications
              </span>
              <strong v-if="metrics.applications">{{metrics.applications || 'Coming soon'}}</strong>
              <b v-else>Coming soon</b>
            </div>
            <div class="item">
              <span class="title">
                <q-icon name="engp:engp-ico-adrs_icon" size="sm"></q-icon>ADRs
              </span>
              <strong v-if="metrics.adrs">{{metrics.adrs || 'Coming soon'}}</strong>
              <b v-else>Coming soon</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import gts from '../../assets/gts.png';
  import {APP_ACTIONS, APP_GETTERS} from "@/store/modules/app";
  import {SERVICES_ACTIONS, SERVICES_GETTERS} from "@/store/modules/services";

  export default {
    name: "HomePage",
    gts,
    computed: {
      ...mapGetters('app', {
        metrics: APP_GETTERS.METRICS,
      }),
      ...mapGetters('services', {
        catalogServices: SERVICES_GETTERS.ALL,
      }),
    },
    methods: {
      ...mapActions('app', {
        getMetrics: APP_ACTIONS.SET_METRICS
      }),
      ...mapActions('services', {
        getCatalog: SERVICES_ACTIONS.GET_SERVICES,
      }),
    },
    async mounted() {
      await this.getMetrics();
      if (!this.catalogServices.length) {
        try {
          await this.getCatalog();
        } catch (error) {
          this.$notify('negative', 'Error', error.message);
        }
      }
    },
  };
</script>

<style lang="scss">
  .landing-page {

    .content-page-holder {
      max-width: 1280px;
    }

    a {
      color: $primary;
    }

    ul {
      list-style-type: none;
    }

    h2 {
      font-size: 80px;
      font-weight: 100;
      line-height: 1.3;
      margin-bottom: 30px;
    }

    .links {
      display: block;
      margin-top: 40px;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 11px;
    }

    color: white;
    background: rgba(241,111,92,1);
    background: -moz-linear-gradient(-45deg, rgba(241,111,92,1) 0%, rgba(85,136,117,1) 0%, rgba(89,43,102,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(241,111,92,1)), color-stop(0%, rgba(85,136,117,1)), color-stop(100%, rgba(89,43,102,1)));
    background: -webkit-linear-gradient(-45deg, rgba(241,111,92,1) 0%, rgba(85,136,117,1) 0%, rgba(89,43,102,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(241,111,92,1) 0%, rgba(85,136,117,1) 0%, rgba(89,43,102,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(241,111,92,1) 0%, rgba(85,136,117,1) 0%, rgba(89,43,102,1) 100%);
    background: linear-gradient(135deg, rgba(241,111,92,1) 0%, rgba(85,136,117,1) 0%, rgba(89,43,102,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f16f5c', endColorstr='#592b66', GradientType=1 );
  }

  .items-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      background-color: rgba(255,255,255,0.2);
      border-radius: 25px;
      margin: 25px 0;

      span {
        display: flex;
        align-items: center;
        color: var(--q-primary);
        font-size: 16px;
        margin-bottom: 10px;

        i {
          margin-right: 10px;
        }
      }

      strong {
        font-size: 80px;
        font-weight: 100;
        line-height: 1;
      }

      b {
        font-weight: 100;
        font-size: 40px;
        line-height: 1;
      }
    }
  }
</style>