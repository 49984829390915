
import { v4 as uuidv4 } from 'uuid';
import {
  ControlElement,
  composePaths,
  createDefaultValue,
  UISchemaElement,
  findUISchema,
  Resolve,
  JsonSchema,
  getFirstPrimitiveProp,
  getControlPath,
} from '@jsonforms/core';
import { defineComponent, ref } from 'vue';
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsArrayControl,
  RendererProps,
} from '@jsonforms/vue';
import {
  useNested,
} from '../util';

const controlRenderer = defineComponent({
  name: 'custom-dns-zones-layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
    manifest: {type: Object, default: () => ({})},
    freezeManifest: {type: Object, default: () => ({})},
    networkPermissions: {type: Object}
  },
  setup(props: RendererProps<ControlElement>) {
    const page = ref<number>(1);
    const showZonesCount = ref<number>(5);
    const control = useJsonFormsArrayControl(props);
    const suggestToDelete = ref<null | number>(null);
    const showFilters = ref<boolean>(false);
    const dnsFilters = ref<any>({
      name: undefined,
      type: undefined,
      value: undefined,
      domain: undefined,
    })
    useNested("array");
    return {
      ...control,
      suggestToDelete,
      showZonesCount,
      page,
      dnsFilters,
      showFilters,
    };
  },
  computed: {
    filteredDnsZones() {
      if (this.control.data?.length) {
        return Object.values(this.control.data).filter((zone: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.dnsFilters) {
            if (this.dnsFilters[prop]) {
              if (prop === 'type') {
                if (this.dnsFilters.type !== zone.type) {
                  return false;
                }
              } else {
                if (!new RegExp(this.dnsFilters[prop], 'i').test(zone[prop])) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      } else {
        return [];
      }
    },
    showingZones() {
      return JSON.parse(JSON.stringify(this.filteredDnsZones)).splice((this.page -1) * this.showZonesCount, this.showZonesCount)
    },
    pages() {
      return Math.ceil(this.filteredDnsZones.length/this.showZonesCount)
    },
    addDisabled(): boolean {
      return (
        !this.networkPermissions?.externalDns?.create ||
        !this.control.enabled ||
        (
          this.arraySchema !== undefined &&
          this.arraySchema.maxItems !== undefined &&
          this.dataLength >= this.arraySchema.maxItems)
      );
    },
    dataLength(): number {
      return this.control.data ? this.control.data.length : 0;
    },
    foundUISchema(): UISchemaElement {
      return findUISchema(
        this.control.uischemas,
        this.control.schema,
        this.control.uischema.scope,
        this.control.path,
        undefined,
        this.control.uischema,
        this.control.rootSchema
      );
    },
    arraySchema(): JsonSchema | undefined {
      return Resolve.schema(
        this.control.rootSchema,
        this.control.uischema.scope,
        this.control.rootSchema
      );
    },
  },
  methods: {
    resetFilters() {
      this.dnsFilters.name = undefined;
      this.dnsFilters.type = undefined;
      this.dnsFilters.value = undefined;
      this.dnsFilters.domain = undefined;
    },
    showFilter() {
      this.showFilters = !this.showFilters;
    },
    elIndex(el: any) {
      return this.control.data.findIndex((a: any) => a.id === el.id)
    },
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.resetFilters();
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema)
      )();
      this.control.data[this.control.data.length - 1].id = uuidv4();
      this.control.data[this.control.data.length - 1].isNewEl = true;
      this.page = this.pages;
    },
    childErrors(index: number) {
      return this.control.childErrors.filter((e) => {
        const errorDataPath = getControlPath(e);
        const splittedErrorPath = errorDataPath.split('.');
        const originErrorPath = [splittedErrorPath[0], splittedErrorPath[1]].join('.');
        return originErrorPath == this.composePaths(this.control.path, `${index}`);
      });
    },
    removeItemsClick(event: Event, toDelete: number[]): void {
      event.stopPropagation();
      this.removeItems?.(this.control.path, toDelete)();
    },
    childLabelForIndex(index: number | null) {
      if (index === null) {
        return '';
      }
      const childLabelProp =
        this.control.uischema.options?.childLabelProp ??
        getFirstPrimitiveProp(this.control.schema);
      if (!childLabelProp) {
        return `${index}`;
      }
      const labelValue = Resolve.data(
        this.control.data,
        composePaths(`${index}`, childLabelProp)
      );
      if (
        labelValue === undefined ||
        labelValue === null ||
        Number.isNaN(labelValue)
      ) {
        return 'Empty zone';
      }
      return `${labelValue}`;
    },
    canBeDelete(element: any) {
      const statuses = ["pending", "inprogress", "processing"];
      return element.markDeleted || statuses.includes(element.objStatus) || (!element.isNewEl && !this.networkPermissions?.externalDns?.delete);
    }
  },
});

export default controlRenderer;
