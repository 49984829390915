import {getAppInfo, getServiceStatuses} from "@/shared/opa-api";
import dayjs from "dayjs";
import apollo from "@/library/http/apollo";
import gql from "graphql-tag";

export const SERVICE_STATUS_GETTERS = {
  ALL: 'serviceStatus'
};

export const SERVICE_STATUS_ACTIONS = {
  GET_SERVICE_STATUS: 'getServiceStatus',
};

const SERVICE_STATUS_MUTATIONS = {
  SET_SERVICE_STATUS: 'setServiceStatus',
};

const statuses = {
  'outage': 4,
  'warning': 3,
  'action': 2,
  'info': 1,
  'available': 0,
}

const sort = (a: any, b: any) => new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const sortByStatus = (a: any, b: any) => statuses[b.status] - statuses[a.status];

export default {
  namespaced: true,
  state: {
    serviceStatus: [],
  },
  getters: {
    [SERVICE_STATUS_GETTERS.ALL]: (state: any) => {
      return state.serviceStatus;
    },
  },
  mutations: {
    [SERVICE_STATUS_MUTATIONS.SET_SERVICE_STATUS]: (state: any, serviceStatus: any) => {
      state.serviceStatus = serviceStatus;
    },
  },
    actions: {
      [SERVICE_STATUS_ACTIONS.GET_SERVICE_STATUS]: async (context: any) => {
        await apollo.apolloClient.query({
          query: gql`
          query { fetchApplicationsServiceStatuses {
            apmId,
            majorIncidents,
            itOwnerEmail,
            businessOwnerEmail,
            itCustodianEmail,
            businessOwnerCustodianEmail,
            itscmCriticality
            digitalServiceName,
            digitalServiceType,
            businessDomain,
            serviceStatuses {
              content,
              enabled,
              name, 
              type,
              publishedAt
            }
          }
        }
        `
        }).then(response => {
          const services = response.data.fetchApplicationsServiceStatuses;
          const now = dayjs(new Date());
          services.forEach((service: any) => {
            service.status =
              service.serviceStatuses?.find((item: any) => item.type === 'outage' && item.enabled) ?  'outage' :
              service.serviceStatuses?.find((item: any) => item.type === 'warning' && item.enabled) ? 'warning' :
              (service.serviceStatuses?.find((item: any) => item.type === 'action' && item.enabled) &&
                !(service.serviceStatuses?.find((item: any) => item.type === 'outage' && item.enabled)) &&
                !(service.serviceStatuses?.find((item: any) => item.type === 'warning' && item.enabled))) ? 'action' :
              (service.serviceStatuses?.find((item: any) => item.type === 'info' && item.enabled && now.diff(item.updatedAt, 'h') < 24) &&
                !(service.serviceStatuses?.find((item: any) => item.type === 'outage' && item.enabled)) &&
                !(service.serviceStatuses?.find((item: any) => item.type === 'warning' && item.enabled)) &&
                !(service.serviceStatuses?.find((item: any) => item.type === 'action' && item.enabled))) ? 'info'  : 'available';
          });
          context.commit(SERVICE_STATUS_MUTATIONS.SET_SERVICE_STATUS, services.sort(sortByStatus));
        });
      },
    },
}