
import {
  ControlElement,
  composePaths,
} from '@jsonforms/core';
import {
  rendererProps,
  RendererProps,
  useJsonFormsMultiEnumControl,
} from '@jsonforms/vue';
import { defineComponent } from 'vue';
import cloneDeep from "lodash/cloneDeep";

const controlRenderer = defineComponent({
  name: 'enum-array-renderer',
  props: {
    ...rendererProps<ControlElement>(),
    manifest: {type: Object, default: () => ({})},
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsMultiEnumControl(props);
  },
  data() {
    return {
      defaultData: []
    }
  },
  mounted() {
    this.defaultData = this.control.data ? cloneDeep(this.control.data) : [];
  },
  methods: {
    isDisabled(value: never) {
      return !!this.defaultData?.includes(value);
    },
    dataHasEnum(value: any) {
      return !!this.control.data?.includes(value);
    },
    composePaths,
    toggle(value: any, add: boolean) {
      if (add) {
        this.addItem(this.control.path, value);
      } else {
        // mistyped in core
        this.removeItem?.(this.control.path, value);
      }
    },
  },
});

export default controlRenderer;

