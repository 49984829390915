<template>
  <q-dialog :model-value="show" @hide="cancel">
    <q-card style="min-width: 768px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Help us improve {{serviceName}}</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="cancel"></q-btn>
      </q-toolbar>
      <q-form ref="form">
        <q-card-section class="q-mx-md q-px-none q-pb-lg" style="border-top: 2px solid var(--q-primary)">
          <p>Note: The feedback is not anonymous and can be seen by other users!</p>
          <div class="q-mb-sm">
            <span>What kind of feedback you give us?</span>
              <q-btn @click="feedbackKind = 'good'" icon="thumb_up" :text-color="feedbackKind === 'good' ? 'green-6' : 'grey-6'" class="btn--no-hover" :ripple="false" flat rounded unelevated>
                <q-tooltip>Praise</q-tooltip>
              </q-btn>
              <q-btn @click="feedbackKind = 'bad'" icon="thumb_down" :text-color="feedbackKind === 'bad' ? 'red-6' : 'grey-6'" class="btn--no-hover q-px-xs" :ripple="false" flat rounded unelevated>
                <q-tooltip>Critique</q-tooltip>
              </q-btn>
          </div>
          <div class="q-mb-sm">
            <p class="q-mb-none">How do you like this service?</p>
            <q-rating
              v-model="rating"
              size="2em"
              icon="star"
              color="grey-6"
              color-selected="yellow-6"
            />
          </div>
          <div class="q-mb-sm">
            <span>Feedback title</span>
            <q-input v-model="title" :rules="[(val) => !!val || 'Field is required']" outlined dense/>
          </div>
          <div class="q-mb-sm">
            <span>Description</span>
            <q-input v-model="description" maxlength="1000" :rules="[(val) => val.length < 1000 || 'Max length is 1000 symbols']" outlined dense type="textarea"/>
          </div>
        </q-card-section>
        <q-card-section v-if="feedbacks.length" class="q-mx-md q-px-none" style="border-top: 2px solid var(--q-primary)">
          <q-expansion-item class="feedback-list">
            <template v-slot:header>
              <span>See what the others said</span>
            </template>
              <div v-for="feedback in showingFeedbacks" :key="feedback.id" class="q-px-md q-mb-md q-pb-md" style="border-bottom: 1px solid #e8e8e8">
                <div class="flex full-width q-mb-xs">
                  <span>{{feedback.title}}</span>
                  <q-icon name="thumb_up" :color="feedback.isLike ? 'green-6' : 'grey-6'" size="18px" class="q-ml-sm"></q-icon>
                  <q-icon name="thumb_down" :color="!feedback.isLike ? 'red-6' : 'grey-6'" size="18px" class="q-ml-xs"></q-icon>
                </div>
                <div class="flex full-width justify-between content-center q-mb-xs">
                  <q-rating
                    :model-value="feedback.stars"
                    size="1.5em"
                    disable
                    icon="star"
                    color="grey-6"
                    color-selected="yellow-6"
                  />
                  <span>Added by: <b>{{feedback.createdBy.name}}</b> {{ $options.dayjs(feedback.createdAt).format('DD.MM.YYYY HH:mm')  }}</span>
                </div>
                <p v-if="feedback.description" class="q-mb-none">
                  {{feedback.description}}
                </p>
              </div>
            <div class="flex flex-center">
              <q-pagination :max="feedbackPages" v-model="feedbackPage"></q-pagination>
            </div>
          </q-expansion-item>
        </q-card-section>
      </q-form>
      <q-card-actions class="q-mx-md q-px-none q-pb-lg q-pt-lg" style="border-top: 2px solid var(--q-primary)">
        <q-btn @click="send" :loading="loading" :disable="!feedbackKind" rounded color="primary">Send</q-btn>
        <q-btn @click="cancel" rounded flat>Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import dayjs from "dayjs";

  export default {
    name: "FeedbackDialog",
    dayjs,
    props: {
      show: {
        type: Boolean,
        default: false
      },
      feedbacks: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
      serviceName: {
        type: String,
        default: () => 'Engineering Portal'
      }
    },
    computed: {
      feedbackPages() {
        return Math.ceil(this.feedbacks.length/this.showFeedbackCount)
      },
      showingFeedbacks() {
        return JSON.parse(JSON.stringify(this.feedbacks)).splice((this.feedbackPage -1) * this.showFeedbackCount, this.showFeedbackCount)
      }
    },
    data() {
      return {
        feedbackPage: 1,
        showFeedbackCount: 3,
        feedbackKind: undefined,
        rating: 0,
        title: '',
        description: ''
      }
    },
    watch: {
      show: {
        handler(value) {
          if (!value) {
            this.cancel();
          }
        },
        deep: true
      }
    },
    methods: {
      send() {
        const data = {};
        data.title = this.title;
        data.description = this.description;
        data.stars = this.rating;
        data.isLike = this.feedbackKind === 'good' || false;
        this.$refs.form.validate().then( async (valid) => {
          if (valid) {
            this.$emit("sendFeedback", data);
          }
        });
      },
      cancel() {
        this.feedbackKind = undefined;
        this.rating = 0;
        this.title = '';
        this.description = '';
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
.q-expansion-item.feedback-list {
  .q-item.q-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 15px;
  }
  .q-expansion-item__content {
    padding-top: 10px;
  }
}
</style>