<template>
  <q-chip
    size="sm"
    :color="backgroundColor"
    text-color="text-color"
    :label="infraService.displayName || infraService.serviceName"
    :title="infraService.displayName || infraService.serviceName"
    clickable
    @click="addService"
  />
</template>

<script>
  import {mapActions} from "vuex";
  import {SERVICE_DESIGN_ACTIONS} from "@/store/modules/service-design";

  export default {
    name: "InfraServiceChip",
    props: {
      infraService: {
        type: Object
      },
      service: {
        type: Object
      }
    },
    computed: {
      backgroundColor() {
        let color;
        if (this.infraService.cloudType === 'Public') {
          color = 'green-2';
        } else if (this.infraService.cloudType === 'Private') {
          color = 'blue-2';
        } else if (this.infraService.cloudType === 'Onprem') {
          color = 'deep-purple-2';
        } else {
          color = 'lime-2';
        }
        return color;
      }
    },
    methods: {
      ...mapActions('serviceDesign', {
        setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
        removeFromDesign: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE
      }),
      addService() {
        this.setInfraService({category: this.service, infraService: this.infraService});
      }
    }
  }
</script>