
  import {
    Layout,
  } from '@jsonforms/core';
  import { defineComponent } from 'vue';
  import {
    DispatchRenderer,
    rendererProps,
    useJsonFormsLayout,
    RendererProps,
  } from '@jsonforms/vue';

  const layoutRenderer = defineComponent({
    name: 'vertical-layout-renderer',
    components: {
      DispatchRenderer,
    },
    props: {
      ...rendererProps<Layout>(),
      manifest: {type: Object, default: () => ({})},
      freezeManifest: {type: Object, default: () => ({})},
      currentEnv: {type:String, default: () => ''}
    },
    setup(props: RendererProps<Layout>) {
      return useJsonFormsLayout(props);
    },
  });

  export default layoutRenderer;
