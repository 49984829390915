<template>
  <div id="chart">
    <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series"></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "CostChart",
  props: {
    costData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        bar: {

        },
        tooltip: {
          x: {
            show: true,
            // formatter: function (val) {
            //   return val + " Cost (USD) w/o VAT";
            // }
          }
        },
        dataLabels: {
          formatter: function (val) {
            return val + "$";
          },
          style: {
            fontSize: '9px',
          }
        },
        colors: ['#896746', '#2e933b', '#b63f24', '#b4881b', '#0b9088', '#8b408f', '#7f9c2e', '#bb316a', '#3169b9'],
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          width: 30,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            dataLabels: {
              total: {
                enabled: true,
                formatter: function (val) {
                  return val ? val.toFixed(2) + "$" : '0$';
                },
                style: {
                  fontSize: '11px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Cost (USD) w/o VAT'
          },
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter: function (val) {
              return val ? val.toFixed(2) + "$" : '0$';
            },
          }
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
    }
  },
  methods: {
    setData() {
      const values = [];
      const arrOfLength = Object.values(this.costData).map(item => item.length);
      const index = arrOfLength.indexOf(Math.max(...arrOfLength));
      Object.values(this.costData)[index].forEach(item => {
        Object.values(this.costData).forEach(costItem => {
          if (!costItem.find(i => i.providerServiceName === item.providerServiceName)) {
            costItem.push({apmId: item.apmId, period: item.period, actualCost: 0, providerServiceName: item.providerServiceName})
          }
        })
        values.push({name: item.providerServiceName, data: []});
      })
      Object.values(this.costData).forEach(item => {
        item.forEach(service => {
          const currentService = values.find(i => i.name === service.providerServiceName);
          currentService?.data.push(service.actualCost ? service.actualCost.toFixed(2) : 0);
        });
      });
      this.series = values;
      this.chartOptions.xaxis.categories = Object.keys(this.costData);
    }
  },
  created() {
      this.setData();
  }
}
</script>