export const BUSINESS_DOMAINS = [
  "Asset Management IT",
  "CorSo IT",
  "Customer Experience & Digital Delivery",
  "Enterprise Architecture",
  "Finance IT",
  "Functional Management",
  "Group Chief Security Office",
  "Group Data Services",
  "Group Technology Services",
  "Life & Health IT",
  "Property & Casualty IT",
  "Risk Management IT",
  "Swiss Re Institute",
  "LH Medical",
  "P&C Reinsurance",
  "Finance IT Shared APM",
  "Group Risk Management",
  "LH Underwriting",
  "Business Objects and Tableau",
  "RAN",
  "GTM",
  "Web Jet Admin",
  "Records management",
  "Life & Health Reinsurance",
  "Property & Casualty Reinsurance",
  "STARGATE",
  "FRP CREDIT",
  "LH Valuations",
  "L&H Reinsurance",
  "Group Underwriting",
  "P&C",
  "SQL database",
  "SICSNUC",
  "Group Finance",
  "NORTH AMERICA CASUALTY",
  "LH Claims",
  "L&H Underwriting",
  "LH Tech Accounting",
  "Corporate Real Estate & Services",
  "L&H Business Management",
  "Stargate",
  "TOSCA",
  "CRES",
  "L&H Project B",
  "LMS",
  "Finance",
  "iptiQ Americas",
  "CEDD",
  "LH Data Management",
  "IptiQ EMEA",
  "PAAS",
  "Group Technology Management",
  "Asset Management",
  "Swiss Re Management Ltd",
  "Legal & Compliance",
  "CORSO - Credit and Surety",
  "Account Validation",
  "Customer Experience & Digital Delivery",
  "Corporate Solutions",
  "Corporate Real Estate Services",
  "P&C Business Management",
  "Information Technology",
  "Reinsurance Origination",
  "P&C BPM",
  "CROSO",
  "CorSo",
  "Finance Reinsurance FP&A",
  "RDSPLUS",
  "CORSO",
  "Corporate Real Estate & Logistics",
  "LH Data Aquisition",
  "Pull Print application",
  "Reinsurance Centre",
  "Finance Reinsurance",
  "Reinsurance Centre Strategy & Operations",
  "Human Resources",
  "L&HCommon Services",
  "CSO",
  "Development platform services",
  "PROGET",
  "Group Operations to manage their Accruals Cost",
  "Life & Health",
  "Costing and Pricing",
  "Group Asset Management",
  "Group Steering",
  "LH Modeling",
  "CALCMENU2",
  "Group Internal Audit Applications",
  "IT Governance Risk and Compliance",
  "Communications",
  "ICP",
  "Business Application",
  "ECTS",
  "RiskAgility Platform",
  "Finance Shared Super APM",
  "Enterprise Service Management",
  "RM IT",
  "IT",
  "shared APMID for Group Operations",
  "GDIS",
  "LH Reporting",
  "L&H IT",
  "EDMS ITGTM",
  "Life & Health Underwriting",
  "L&H Analytics",
  "HR",
  "IptiQ",
  "Legal and Compliance",
  "CLSTRSVN",
  "Corporate Solution",
  "swiss re",
  "Global Business Solutions",
  "LH Common Component"
]