<template>
  <div class="q-py-xs q-mb-sm template-holder">
    <div class="template-heading flex justify-between items-center">
      <div class="template-name flex items-center">
        <q-icon name="receipt_long" color="primary" size="sm" class="q-mr-xs"></q-icon>
        <h6 class="q-my-none">{{ templateData.name }}</h6>
      </div>
      <div class="template-actions flex items-center">
        <span v-if="templateType === 'custom'" style="cursor: pointer" class="q-mr-sm">used by <q-icon name="mdi-account-outline" size="xs"></q-icon>{{templateData.viewers.length}} users
          <q-tooltip style="font-size: 12px">{{templateData.viewers.join(', ')}}</q-tooltip>
        </span>
        <q-btn
          @click="showInfo = !showInfo"
          :ripple="false"
          flat
          v-if="templateData.description"
          class="q-mx-sm q-pb-sm q-px-sm border-left btn--no-hover" :icon="showInfo ? 'mdi-close-circle-outline' : 'mdi-information-outline'"
          size="12px"
          :class="{open : showInfo}"
        >
          <q-tooltip>{{ showInfo ? 'Close Info' : 'Show Info' }}</q-tooltip>
        </q-btn>
        <q-btn v-if="templateType === 'custom'" @click="edit" :ripple="false" icon="mdi-pencil-outline" flat class="q-mx-sm border-left q-px-sm btn--no-hover" size="12px">
          <q-tooltip>Edit</q-tooltip>
        </q-btn>
        <q-btn v-if="templateType === 'custom'" @click="showDeleteConfirm = true" :ripple="false" icon="mdi-delete-outline" flat class="q-mx-sm border-left q-px-sm btn--no-hover" size="12px">
          <q-tooltip>Delete</q-tooltip>
        </q-btn>
        <q-btn v-if="templateType === 'custom'" @click="openShareDialog" :ripple="false" icon="mdi-share-variant-outline" flat class="q-mx-sm q-px-sm border-left btn--no-hover" size="12px">
          <q-tooltip>Share</q-tooltip>
        </q-btn>
        <q-btn :disable="!currentService || !canBeRunned" @click="runTemplate" :ripple="false" icon="mdi-play-circle-outline" flat class="q-mx-sm border-left q-px-sm btn--no-hover" size="12px">
          <q-tooltip max-width="320px">Add the template to your Digital Service Design. A digital Service needs to be chosen from the drop down above. Click the "Finish and Setup" button in the Digital Service Design area to onboard onto the chosen Infrastructure Services</q-tooltip>
        </q-btn>
      </div>
    </div>
    <transition appear leave-active-class="animated fadeOutUp" enter-active-class="animated fadeInDown">
      <div v-if="showInfo" class="info flex no-wrap items-center">
        <q-icon name="mdi-information-outline" color="lime-10" size="80px" class="q-mr-lg"></q-icon>
        <div class="template-description-markdown" v-html="templateDescription"></div>
      </div>
    </transition>
    <div class="row wrap">
      <div class="col col-2 q-px-xs" v-for="service in templateData.services" :key="service.categoryId">
        <span class="label">{{service.name}}</span>
        <div v-if="!service.infraServices.length">
          <span class="no-content">No services</span>
        </div>
        <infra-service v-for="infraService in service.infraServices" :data-service="infraService" :key="infraService.uuid"></infra-service>
      </div>
    </div>
  </div>

  <q-dialog v-model="showTemplatePopup" persistent style="z-index: 999999;">
    <q-card style="min-width: 90%;" class="q-px-lg q-py-lg">
      <template-popup
        :design="design"
        :templateName="templateData.name"
        :templateDescription="templateData.description"
        editTemplate
        @close="closePopup"
        @createTemplate="updateTemplate"
      />
    </q-card>
  </q-dialog>

  <q-dialog v-model="showDeleteConfirm" class="single-service-page" persistent>
    <q-card style="min-width: 480px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Delete Template</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showDeleteConfirm = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <p>Deleting template can not be undone. Would you like to continue?</p>
      </q-card-section>
      <q-card-actions class="q-px-md q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="deleteTemplate()">Confirm</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showDeleteConfirm = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="shareDialog" class="single-service-page" persistent>
    <q-card style="min-width: 480px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Share template</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="closeShareDialog"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-select
          label="Select users"
          use-input
          input-debounce="0"
          v-model="usersForShare"
          option-label="name"
          option-value="email"
          @filter="filterUsers"
          emit-value
          options-dense
          map-options
          :options="users"
          multiple
          outlined
          :loading="loadUsers"
          :disable="loadUsers"
          class="users-select"
          popup-content-class="users-menu"
        >
        </q-select>
      </q-card-section>
      <q-card-actions class="q-px-md q-pt-none q-pb-lg q-mt-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" :disable="!this.usersForShare.length" color="primary" text-color="text-color" :loading="shareLoading" @click="shareTemplate()">Share</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="closeShareDialog">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
  import {marked} from "marked";
  import emitter from "tiny-emitter/instance";
  import {mapActions, mapGetters} from "vuex";
  import {SERVICE_DESIGN_ACTIONS, SERVICE_DESIGN_GETTERS} from "@/store/modules/service-design";
  import InfraService from "./InfraService";
  import TemplatePopup from "@/shared/components/MyTemplatePopup";
  import apollo from "@/library/http/apollo";
  import gql from "graphql-tag";

  export default {
    name: 'ServiceTemplate',
    components: {
      InfraService,
      TemplatePopup,
    },
    emits: [
      'delete',
      'load',
    ],
    props: {
      templateData: {
        type: Object
      },
      templateType: {
        type: String,
      },
      currentService: {
        type: Object
      }
    },
    computed: {
      ...mapGetters('serviceDesign', {
        design: SERVICE_DESIGN_GETTERS.ALL
      }),
      templateDescription() {
        return this.templateData.description ? marked(this.templateData.description) : '';
      },
    },
    data() {
      return {
        showInfo: false,
        canBeRunned: true,
        showDeleteConfirm: false,
        showTemplatePopup: false,
        shareDialog: false,
        usersForShare: [],
        users: [],
        allUsers: [],
        loadUsers: true,
        shareLoading: false,
      }
    },
    created() {
      emitter.on('canBeRunned', () => {
        this.canBeRunned = true;
      });
      emitter.on('clearServiceSelect', () => {
        this.canBeRunned = true;
      })
    },
    unmounted() {
      emitter.off('canBeRunned');
      emitter.off('clearServiceSelect');
    },
    methods: {
      ...mapActions('serviceDesign', {
        setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
      }),
      async runTemplate(showMessage = true) {
        const infraServices = [];
        this.templateData.services.forEach(category => {
          category.infraServices.forEach(service => {
            const cat = this.design.find(c => c.categoryId === service.parentCategoryId || c.categoryId === service.categoryId);
            if (!(cat && cat.infraServices?.find(infra => infra.id === service.id))) {
              infraServices.push(service);
            }
          })
        });
        const promises = infraServices.map((item) => {
          const infraServiceData = {
            category: {
              categoryId: item.parentCategoryId || item.categoryId
            },
            infraService: item,
          };
          return this.setInfraService(infraServiceData);
        })
        await Promise.all(promises).finally(() => {
          if (showMessage) {
            this.$notify('info', 'Info', `Template ${this.templateData.name} has been successfully applied.`);
          }
          this.canBeRunned = false;
        })
      },
      deleteTemplate() {
        this.$emit('delete', this.templateData.id);
        this.showDeleteConfirm = false;
      },
      edit() {
        this.runTemplate(false);
        this.showTemplatePopup = true
      },
      async updateTemplate(templateInfo) {
        const services = this.design.map(category => {
          return category.infraServices.map(service => {
            return service;
          });
        });
        const infraServices = (services.reduce((prev, curr) => {
          return [...prev, ...curr];
        }, [])).map(item => item.serviceId);
        const templateData = {
          name: templateInfo.name,
          description: templateInfo.description,
          services: infraServices
        };
        const templateId = this.templateData.id;
        await apollo.apolloClient.mutate({
          mutation: gql`
            mutation editTemplate(
                $changes: FrontendTemplateChangesInput!
                $templateId: String!
            ) {
              editTemplate(
                changes: $changes,
                templateId: $templateId,
              ) {
                name,
                description,
                services {
                  apmid
                }
              }
            }
          `,
          variables: {
            templateId: templateId,
            changes: templateData
          }
        }).then(() => {
          this.$notify('positive', 'Success', 'Template was updated successfully.');
          this.$emit('load');
        }).catch((error) => {
          this.$notify('negative', 'Error', error.message);
        });
      },
      closePopup() {
        this.showTemplatePopup = false;
        emitter.emit('canBeRunned');
      },
      async openShareDialog() {
        this.shareDialog = true;
        if (!this.allUsers.length) {
          await apollo.apolloClient.query({
            query: gql`
              query {
                users {
                  name,
                  email
                }
              }
            `
          }).then((response) => {
            this.allUsers = response.data.users.filter(user => !this.templateData.viewers.includes(user.email));
          }).catch((error) => {
            this.$notify('negative', 'Error', error.message);
          }).finally(() => {
            this.loadUsers = false;
          });
        } else {
          this.shareDialog = true;
        }
      },
      closeShareDialog() {
        this.shareDialog = false;
        this.usersForShare = [];
      },
      async shareTemplate() {
        this.shareLoading = true;
        const templateId = this.templateData.id;
        const shareWith = this.usersForShare;
        await apollo.apolloClient.mutate({
          mutation: gql`
            mutation shareTemplate(
                $shareWith: [String!]!
                $templateId: String!
            ) {
              shareTemplate(
                shareWith: $shareWith,
                templateId: $templateId,
              ) {
                name
              }
            }
          `,
          variables: {
            templateId: templateId,
            shareWith: shareWith
          }
        }).then(() => {
          this.$notify('positive', 'Success', `Template ${this.templateData.name} was shared successfully.`);
          this.allUsers = [];
          this.$emit('load');
        }).catch((error) => {
          this.$notify('negative', 'Error', error.message);
        }).finally(() => {
          this.shareLoading = false;
          this.closeShareDialog();
        })
      },
      filterUsers(val, update) {
        update(() => {
          const needle = val ? val.toLowerCase() : '';
          this.users = this.allUsers.filter(v => v.name?.toLowerCase().indexOf(needle) > - 1 || v.email?.toLowerCase().indexOf(needle) > -1);
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .template-holder {
    border-top: 2px solid $primary;

    .label {
      display: block;
      min-height: 30px;
      text-align: center;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #A5A5A5;
      font-weight: bold;
    }

    h6 {
      font-size: 18px;
      font-weight: bold;
    }

    .border-left {
      &:before {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #b3b9bb;
        position: absolute;
        left: -8px;
        top: 5px;
      }
    }

    .border-right {
      &:after {
        content: '';
        width: 1px;
        height: 20px;
        background-color: #b3b9bb;
        position: absolute;
        right: -8px;
        top: 5px;
      }
    }
  }

  .no-content {
    display: block;
    text-align: center;
    font-size: 12px;
  }

  .open {
    background-color: #F9F6E3;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .info {
    background-color: #F9F6E3;
    padding: 25px;
    margin-top: -1px;
    margin-bottom: 10px;
    border-radius: 15px;
  }
</style>