<template>
    <q-table
            :columns="accessProductColumns"
            :rows="tableData"
            class="table-info access-products-table"
            flat
    >
        <template v-slot:body-cell-displayName="props">
            <q-td style="width: 30px;" :props="props">
                <div>{{ props.row.displayName }}</div>
                <div>{{ props.row.displayDescription }}</div>
            </q-td>
        </template>
        <template v-slot:body-cell-getAccessLink="props">
            <q-td style="width: 30px;" :props="props">
                <a :href="props.row.getAccessLink" target="_blank">Get access</a>
            </q-td>
        </template>
        <template v-slot:body-cell-environment="props">
            <q-td style="width: 30px;" :props="props">
              <div class="flex items-center">
                <q-icon name="check_circle" color="blue-5" size="18px" class="q-mr-xs"></q-icon>
                <span>{{ props.row.environment }}</span>
              </div>
            </q-td>
        </template>
    </q-table>
</template>
<script>

export default {
    name: "AccessProductsTable",
    props: {
        accessProducts: {
            type: Object
        },
        showAll: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            accessProductColumns: [
                {
                    label: 'Access role name',
                    name: 'displayName',
                    field: 'displayName',
                    align: 'left'
                },
                {
                    label: 'Environment',
                    name: 'environment',
                    field: 'environment',
                    align: 'left'
                },
                {
                    label: 'Request Access Link',
                    name: 'getAccessLink',
                    field: 'getAccessLink',
                    align: 'left'
                },
            ],
        }
    },
    computed: {
        tableData() {
            return Object
                .values(this.accessProducts)
                .flat(1)
                .map(accessProduct => {
                    const {
                        role: {
                            displayName,
                            description: displayDescription,
                            _environmentName: environment
                        }
                    } = accessProduct;

                    return {
                        displayName,
                        displayDescription,
                        environment,
                        getAccessLink: `https://swissreesm.service-now.com/contactone?id=request_access_rights&accessRightParam=${displayName}`,
                    }
                })
        }
    },
}
</script>

<style lang="sass">
.access-products-table
  max-height: 380px
  thead tr
    height: 35px !important
  thead tr:first-child th
    background-color: #edeff5
  thead tr th
    position: sticky
    z-index: 1
    border-bottom-width: 0 !important
  thead tr:first-child th
    top: 0
  &.q-table--loading thead tr:last-child th
    top: 48px
</style>