
import {
  ControlElement,
} from '@jsonforms/core';
import { defineComponent } from 'vue';
import {
  RendererProps,
  rendererProps,
  useJsonFormsControl,
} from '@jsonforms/vue';

const controlRenderer = defineComponent({
  name: 'boolean-control-renderer',
  props: {
    ...rendererProps<ControlElement>(),
    isNewEl: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      disable: false,
      inputData: false
    }
  },
  mounted() {
    this.disable = this.control.uischema.options && !this.control.uischema.options.editable && !this.isNewEl ? true : false;
    this.onChange(this.control.data ? this.control.data : false);
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props)
  },
  methods: {
    onChange(event: any) {
      this.handleChange(
        this.control.path,
        event
      );
    }
  }
});
export default controlRenderer;

