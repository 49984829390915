<template>
  <div class="actions-filter-holder row wrap">
    <div class="col q-mx-xs">
      <q-input v-model="filters.actionName" label="Action Name" dense outlined bg-color="white"></q-input>
    </div>
    <div class="col q-mx-xs">
      <q-select
        v-model="filters.objStatus"
        :options="[
          {label: 'Completed', value: 'completed'},
          {label: 'In progress', value: 'inprogress'},
          {label: 'Pending', value: 'pending'},
          {label: 'Failed', value: 'failed'}
        ]"
        emit-value
        option-value="value"
        map-options
        option-label="label"
        label="Status"
        dense
        options-dense
        outlined
        bg-color="white"></q-select>
    </div>
    <div class="col q-mx-xs">
      <q-select
        v-model="filters.environment"
        :options="[
          {label: 'Dev', value: 'DEV'},
          {label: 'Non Prod', value: 'NONPROD'},
          {label: 'Prod', value: 'PROD'}
        ]"
        emit-value
        option-value="value"
        map-options
        option-label="label"
        label="Environment"
        dense
        options-dense
        outlined
        bg-color="white"></q-select>
    </div>
    <div class="col q-mx-xs">
      <q-input v-model="filters.createdBy" label="Created by" dense outlined bg-color="white"></q-input>
    </div>
    <q-btn @click="resetFilters" v-if="showShare" icon="refresh" flat :ripple="false" round>
      <q-tooltip>Reset</q-tooltip>
    </q-btn>
  </div>
  <q-table
    :rows="filteredActions"
    :columns="actionCols"
    flat
    class="table-info table-actions"
    :pagination="{
      rowsPerPage: showAll ? 0 : 5
    }"
    :hide-bottom="showAll"
  >
    <template v-slot:no-data>
      <q-icon name="mdi-cloud-search" class="q-mb-sm" size="60px" color="primary"></q-icon>
      <span class="block" style="font-size: 14px">No items were found.</span>
    </template>
    <template v-slot:top-left v-if="actions.length && !showAll">
      <q-btn @click="$emit('showActions')" size="12px" class="q-px-xs text-blue-4 text-capitalize" flat>View all actions</q-btn>
    </template>
    <template v-slot:body-cell-objStatus="props">
      <q-td style="width: 30px;" :props="props">
        <div class="status">
          <span :class="props.row.objStatus ? props.row.objStatus.toLowerCase() : ''">
            <q-tooltip>{{ props.row.objStatus || '' }}</q-tooltip>
          </span>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-createdBy="props">
      <q-td :props="props">
        {{props.row.createdBy.name}}
      </q-td>
    </template>
    <template v-slot:body-cell-changeRequest="props">
      <q-td :props="props">
        <a v-if="props.row.changeAction" :href="props.row.changeAction?.link" target="_blank">
          {{ props.row.changeAction?.number }}
        </a>
        <q-icon v-else name="remove"></q-icon>
      </q-td>
    </template>
    <template v-slot:body-cell-updated="props">
      <q-td :props="props">
        {{$options.dayjs(props.row.updated).format('DD.MM.YYYY h:m A')}}
      </q-td>
    </template>
    <template v-slot:body-cell-id="props">
      <q-td :props="props">
        <q-btn flat :ripple="false" size="12px" style="text-transform: none" text-color="blue-6" @click="gotToAction(props.row?.id)">Go to action</q-btn>
      </q-td>
    </template>
  </q-table>
</template>

<script>
  import dayjs from "dayjs";

  export default {
    name: "ActionsTable",
    dayjs,
    props: {
      actions: {
        type: Array
      },
      showAll: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        filters: {
          actionName: undefined,
          objStatus: undefined,
          environment: undefined,
          createdBy: undefined,
        },
        actionCols: [
          {
            label: 'Status',
            name: 'objStatus',
            field: 'objStatus',
            align: 'left'
          },
          {
            label: 'Name',
            name: 'actionName',
            field: 'actionName',
            align: 'left'
          },
          {
            label: 'Environment',
            name: 'environment',
            field: 'environment',
            align: 'left'
          },
          {
            label: 'Created By',
            name: 'createdBy',
            field: 'createdBy',
            align: 'left'
          },
          {
            label: 'Updated At',
            name: 'updated',
            field: 'updated',
            align: 'left'
          },
          {
            label: 'Change Request',
            name: 'changeRequest',
            field: (row) => row.lastChangeRequest.link,
            align: 'left'
          },
          {
            label: 'Link to action',
            name: 'id',
            field: 'id',
            align: 'left'
          },
        ],
      }
    },
    computed: {
      showShare() {
        return Object.keys(this.filters).some((key) => this.filters[key] && this.filters[key].length);
      },
      filteredActions() {
        return this.actions.filter((action) => {
          for (const prop in this.filters) {
            if (!Array.isArray(this.filters[prop]) && this.filters[prop] && this.filters[prop].trim()) {
              if (!new RegExp(this.filters[prop], "i").test(action[prop]) || this.filters[prop] === "PROD" && action[prop] === 'NONPROD') {
                return false;
              }
            }
          }
          return true;
        });
      },
    },
    methods: {
      gotToAction(id) {
        const env = process.env.VUE_APP_ENV;
        const links = {
          'PROD': 'https://appreg.ac.swissre.com/#/',
          'NONPROD': 'https://appreg.ac-np.swissre.com/#/',
          'DEV': 'https://appreg-dev.ac-np.swissre.com/#/'
        };
        if (id) {
          window.open(`${links[env]}actions/${id}`, '_blank');
        }
      },
      resetFilters() {
        Object.keys(this.filters).map(key => {
          this.filters[key] = undefined;
        });
      }
    }
  }
</script>

<style lang="sass">
.table-actions
  max-height: 320px
  thead tr:first-child th
    background-color: #edeff5
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
  &.q-table--loading thead tr:last-child th
    top: 48px
  tbody
    scroll-margin-top: 48px
  .q-table__bottom--nodata
    height: calc(100% - 25px)
    flex-direction: column
    padding: 0
    margin: 0
    align-items: center
    justify-content: center
</style>