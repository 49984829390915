
  import {
    ControlElement,
  } from '@jsonforms/core';
  import { defineComponent } from 'vue';
  import {
    rendererProps,
    RendererProps,
    useJsonFormsControl,
  } from '@jsonforms/vue';

  const controlRenderer = defineComponent({
    name: 'date-control-renderer',
    props: {
      ...rendererProps<ControlElement>(),
      networkCanBeChanged: {type: Boolean, default: true},
      dense: {type: Boolean, default: false},
      dateCanBeEdit: {type: Boolean, default: false},
      isNewEl: {type: Boolean, default: false},
      dataElement: {type: Object},
    },
    setup(props: RendererProps<ControlElement>) {
      return useJsonFormsControl(props)
    },
    data() {
      return {
        date: undefined,
       
      }
    },
    methods: {
      onChange(value: any) {
        this.handleChange(
          this.control.path,
          value
        );
      },
      validateOptions (date:string) {
          if(this.control.uischema.label === 'Start date') {
            const givenDate = new Date(date);
            const currentDate = new Date();

            return currentDate < givenDate;
          }

          if(this.control.uischema.label === 'End date') {
            const givenDate = new Date(date);
            const startDate = this?.dataElement?.startDate ? new Date(this?.dataElement?.startDate) : new Date();

            return startDate < givenDate;
          }
        }
    },
  });

  export default controlRenderer;
