import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';;
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-symbols-outlined/material-symbols-outlined.css";
import "@quasar/extras/mdi-v6/mdi-v6.css";
import "@quasar/extras/animate/fadeOutLeft.css";
import "@quasar/extras/animate/fadeInLeft.css";
import "@quasar/extras/animate/fadeOutUp.css";
import "@quasar/extras/animate/fadeOutDown.css";
import "@quasar/extras/animate/fadeInUp.css";
import "@quasar/extras/animate/fadeInDown.css";
import "@quasar/extras/animate/fadeIn.css";
import "./styles/icomoon.css";
import "./styles/quasar.scss";

// To be used on app.use(Quasar, { ... })
export default {
  plugins: [
    Loading,
    Notify,
  ],
  animations: 'all',
  config: {
    loading: {
      spinner: QSpinnerGears,
      spinnerSize: 100,
      spinnerColor: 'primary',
      backgroundColor: 'white',
      customClass: 'main-loading',
    },
    notify: {}
  },
};
