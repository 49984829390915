import apollo from "@/library/http/apollo";
import gql from 'graphql-tag';

export const DIGITAL_SERVICES_GETTERS = {
  ALL_APPLICATIONS: 'allApplications',
  ACTIVE_APPLICATIONS: 'activeApplications',
  CURRENT_SERVICE: 'currentService',
};

export const DIGITAL_SERVICES_ACTIONS = {
  GET_ALL_APPLICATIONS: 'getAllApplications',
  GET_ACTIVE_APPLICATIONS: 'getActiveApplications',
  ADD_DIGITAL_SERVICE: 'addDigitalService',
  SET_CURRENT_SERVICE: 'setCurrentService',
  REMOVE_CURRENT_SERVICE: 'removeCurrentService',
};

const DIGITAL_SERVICES_MUTATIONS = {
  SET_ALL_APPLICATIONS: 'setAllApplications',
  SET_ACTIVE_APPLICATIONS: 'setActiveApplications',
  SET_CURRENT_SERVICE: 'setCurrentService',
  REMOVE_CURRENT_SERVICE: 'removeCurrentService',
};

export default {
  namespaced: true,
  state: {
    applications: [],
    activeApplications: [],
    services: [],
    currentService: undefined,
  },
  getters: {
    [DIGITAL_SERVICES_GETTERS.ALL_APPLICATIONS]: (state: any) => {
      return state.applications;
    },
    [DIGITAL_SERVICES_GETTERS.ACTIVE_APPLICATIONS]: (state: any) => {
      return state.activeApplications;
    },
    [DIGITAL_SERVICES_GETTERS.CURRENT_SERVICE]: (state: any) => {
      return state.currentService;
    },
  },
  mutations: {
    [DIGITAL_SERVICES_MUTATIONS.SET_CURRENT_SERVICE]: (state: any, service: any) => {
      state.currentService = service;
    },
    [DIGITAL_SERVICES_MUTATIONS.SET_ALL_APPLICATIONS]: (state: any, applications: any) => {
      state.applications = applications;
    },
    [DIGITAL_SERVICES_MUTATIONS.REMOVE_CURRENT_SERVICE]: (state: any) => {
      state.currentService = undefined;
    },
    [DIGITAL_SERVICES_MUTATIONS.SET_ACTIVE_APPLICATIONS]: (state: any, applications: any) => {
      state.activeApplications = applications;
    },
  },
  actions: {
    [DIGITAL_SERVICES_ACTIONS.SET_CURRENT_SERVICE]: async (context: any, id: string) => {
      await context.dispatch(DIGITAL_SERVICES_ACTIONS.ADD_DIGITAL_SERVICE, id);
    },
    [DIGITAL_SERVICES_ACTIONS.ADD_DIGITAL_SERVICE]: async (context: any, id: string ) => {
      await apollo.apolloClient.query({
        query: gql`mutation { 
          addDigitalServiceToUserViewList(digitalServiceId: "${id}") {
            id
            apmId
            digitalServiceName
            itOwnerEmail
            businessOwnerEmail
            itCustodianEmail
            businessOwnerCustodianEmail
            registrations {
              status
              infraService {
                apmid
                displayName
                serviceName
                cloudType
                type
                categoryId
              }
            } 
          }
        }`
      }).then((response) => {
        const service = response.data.addDigitalServiceToUserViewList;
        context.commit(DIGITAL_SERVICES_MUTATIONS.SET_CURRENT_SERVICE, service);
        const infraServices = service.registrations.map((item: any) => item.infraService);
        const promises = infraServices.map((item: any) => {
          item.notCanBeDeleted = true;
          const infraServiceData = {
            category: {
              categoryId: item.categoryId
            },
            infraService: item,
          };
          return context.dispatch('serviceDesign/setInfraservice', infraServiceData, {root: true})
        })
        Promise.all(promises);
      });
    },
    [DIGITAL_SERVICES_ACTIONS.GET_ALL_APPLICATIONS]: async (context: any) => {
      await apollo.apolloClient.query({
        query: gql`
          query { getNotOwnedByMeApplications {
            id
            apmId
            digitalServiceName
            }
          }
        `
      }).then(response => {
        const services = response.data.getNotOwnedByMeApplications;
        context.commit(DIGITAL_SERVICES_MUTATIONS.SET_ALL_APPLICATIONS, services);
      })
    },
    [DIGITAL_SERVICES_ACTIONS.GET_ACTIVE_APPLICATIONS]: async (context: any) => {
      await apollo.apolloClient.query({
        query: gql`
          query { digitalService (active: true, owner: true) {
            id
            apmId
            digitalServiceName
           
          }
        }
        `
      }).then(response => {
        const services = response.data.digitalService;
        context.commit(DIGITAL_SERVICES_MUTATIONS.SET_ACTIVE_APPLICATIONS, services);
      });
    },
    [DIGITAL_SERVICES_ACTIONS.REMOVE_CURRENT_SERVICE]: async (context: any) => {
      await context.commit(DIGITAL_SERVICES_MUTATIONS.REMOVE_CURRENT_SERVICE);
      await context.dispatch('serviceDesign/resetServiceDesign', null, {root: true});
    }
  },
}