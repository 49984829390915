
import {
  ControlElement,
} from '@jsonforms/core';
import { defineComponent } from 'vue';
import {
  rendererProps,
  useJsonFormsControl,
  RendererProps,
} from '@jsonforms/vue';

const controlRenderer = defineComponent({
  name: 'string-control-renderer',
  components: {
  },
  props: {
    ...rendererProps<ControlElement>(),
    freezeManifest: {type: Object, default: () => ({})},
    isNewEl: {type: Boolean, default: false},
    networkCanBeChanged: {type: Boolean, default: true},
    dataElement: {type: Object},
    manifest: {type: Object},
    isNetwork: {
      type: Boolean,
      default: () => false
    },
    disableUpdateOpass: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
  },
  data() {
    return {
      disable: false,
    }
  },
  mounted() {
    if (!this.isNewEl) {
      this.disable = this.disableUpdateOpass || !this.networkCanBeChanged || !this.control.enabled ? true : this.control.data && this.control.data.length && this.control.uischema.options && !this.control.uischema.options.editable ? true : false;
    }
     this.onChange(this.control.data);
  },
  methods: {
    onChange(value: any) {
      this.handleChange(
        this.control.path,
        value ? value.trim(' ') : undefined
      );
    }
  },
  computed: {
    fieldRules() {
      const scopeItems = this.uischema.scope.split('/');
      const item = scopeItems[scopeItems.length - 1];
      const rules = [];
      if (item === 'value' && this.isNetwork && this.isNewEl && this.dataElement?.type === 'NS') {
        rules.push((val: string) => (this.manifest?.externalDNSZones.filter((i:any) => i.type === 'NS' && i.domain === this.dataElement?.domain && i.name === this.dataElement?.name && i.value === val)).length === 1 || 'The value field must be unique for records with the NS type and the same names');
      }
      if (item === 'value' && this.isNetwork && this.isNewEl && this.dataElement?.type === 'A') {
        rules.push((val: string) => (this.manifest?.externalDNSZones.filter((i:any) => i.type === 'A' && i.domain === this.dataElement?.domain && i.name === this.dataElement?.name && i.value === val)).length === 1 || 'The value field must be unique for records with the A type and the same names');
      }
      if (item === 'value' && this.isNetwork && this.isNewEl && this.dataElement?.type === 'AAAA') {
        rules.push((val: string) => (this.manifest?.externalDNSZones.filter((i:any) => i.type === 'AAAA' && i.domain === this.dataElement?.domain && i.name === this.dataElement?.name && i.value === val)).length === 1 || 'The value field must be unique for records with the AAAA type and the same names');
      }
      if (item === 'value' && this.isNetwork && this.isNewEl && this.dataElement?.type === 'CNAME') {
        rules.push((val: string) => (this.manifest?.externalDNSZones.filter((i:any) => i.type === 'CNAME' && i.domain === this.dataElement?.domain && i.name === this.dataElement?.name && i.value === val)).length === 1 || 'The value field must be unique for records with the CNAME type and the same names');
      }
      if (item === 'value' && this.isNetwork && this.isNewEl && this.dataElement?.type === 'CNAME-WITH-REDIRECT') {
        rules.push((val: string) => (this.manifest?.externalDNSZones.filter((i:any) => i.type === 'CNAME-WITH-REDIRECT' && i.domain === this.dataElement?.domain && i.name === this.dataElement?.name && i.value === val)).length === 1 || 'The value field must be unique for records with the CNAME-WITH-REDIRECT type and the same names');
      }
      if (this.control.required) {
        rules.push((val: string) => !!val || 'Field is required');
      }
      if (this.control.schema.pattern) {
        const pattern = new RegExp(this.control.schema.pattern);
        rules.push((v: any) => pattern.test(v) || 'No valid value')
      }
      if (this.control.errors) {
        const pattern = new RegExp(this.control.errors);
        rules.push((v: any) => pattern.test(v) || this.control.errors)
      }
      return rules;
    }
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props)
  },
});

export default controlRenderer;
