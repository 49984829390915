<template>
  <div class="infra-service" :class="[`${dataService.cloudType ? dataService.cloudType.toLowerCase() : 'na'}`, { tool : dataService.type === 'Tool'}]">
    <div class="info-block">
      <span class="icon-service">
        <infra-service-logo v-if="dataService.icon && dataService.icon !== 'default'" :logo="dataService.icon"/>
        <q-icon v-else name="settings" size="30px" color="white"></q-icon>
      </span>
      <span class="service-name">
        {{dataService.displayName || dataService.serviceName}}
        <sup push>
        <q-icon v-if="dataService.description && dataService.description.trim().length" name="mdi-information-outline" color="grey-7" size="14px">
           <q-popup-proxy>
              <q-banner class="service-description-markdown" v-html="dataMarkdown"/>
           </q-popup-proxy>
        </q-icon>
        </sup>
      </span>
    </div>
    <div class="action-block">
      <span class="service-label q-ml-auto">
        <q-icon v-if="isSharedService" name="share" size="xs"></q-icon>
        <q-icon v-if="isStratum" name="engp:engp-ico-stratum" size="xs" class="q-ml-xs"/>
        <q-icon v-if="isCertProduct" name="engp:engp-ico-cert_product" size="xs" class="q-ml-xs"/>
        <q-icon v-if="isPlanned" name="engp:engp-ico-planned" size="xs" class="q-ml-xs"/>
        <q-icon v-if="isDeprecated" name="engp:engp-ico-deprecated" size="xs" class="q-ml-xs"/>
      </span>
      <div class="btn-group" @mouseover="activeClass = true" @mouseleave="activeClass = false" :class="{hover: activeClass}">
        <q-btn v-if="notCanBeDeleted" class="btn--no-hover" size="12px" style="cursor: not-allowed" icon="lock" flat padding="0" :ripple="false">
          <q-tooltip>Can not be removed from service design</q-tooltip>
        </q-btn>
        <span v-else>
          <q-btn
            v-if="dataService.orderable ? !isInDesign || !activeClass : false"
            @click="addToDesign"
            class="btn--no-hover"
            :icon="isInDesign ? 'check' : 'add'"
            flat padding="0"
            :ripple="false"
            :disable="uiConfig?.disableEditServices?.includes(dataService?.apmid) || uiConfig?.hideServices?.includes(dataService?.apmid)"
          >
            <q-tooltip v-if="!isInDesign">Add to Service Design</q-tooltip>
          </q-btn>
          <q-btn v-if="dataService.orderable ? activeClass && isInDesign : false" @click="removeFromServiceDesign" class="btn--no-hover" icon="remove" flat padding="0" :ripple="false">
            <q-tooltip>Remove from Service Design</q-tooltip>
          </q-btn>
        </span>
        <q-btn @click="goToInfraService" class="btn--no-hover" icon="more_horiz" flat padding="0" :ripple="false">
          <q-tooltip>More Info</q-tooltip>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import {SERVICE_DESIGN_ACTIONS} from "@/store/modules/service-design";
  import { SERVICE_TYPE } from "@/constatns";
  import {marked} from "marked";
  import InfraServiceLogo from "@/shared/components/InfraServiceLogo.vue";
  import * as uiConfig from "@/shared/ui-config.json";

  export default {
    name: 'InfraService',
    components: {
      InfraServiceLogo
    },
    SERVICE_TYPE,
    props: {
      service: {
        type: Object
      },
      dataService: {
        type: Object
      },
      design: {
        type: Array
      }
    },
    data() {
      return {
        activeClass: false,
        uiConfig: {}
      }
    },
    mounted() {
      this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    },
    computed: {
      dataMarkdown() {
        return this.dataService.description && this.dataService.description ? marked(this.dataService.description) : '';
      },
      isInDesign() {
        const category = this.design.find(category => category.categoryId === this.service.categoryId);
        return (category.infraServices.some(infra => infra.apmid === this.dataService.apmid));
      },
      notCanBeDeleted() {
        const category = this.design.find(category => category.categoryId === this.service.categoryId);
        return (category.infraServices.some(infra => infra.apmid === this.dataService.apmid && infra.notCanBeDeleted));
      },
      isPaved() {
        const { pavedRoad} = this.dataService;
        return pavedRoad;
      },
      isDeprecated() {
        return this.dataService.state === 'Deprecated';
      },
      isPlanned() {
        return this.dataService.state === 'Planned';
      },
      isCertProduct() {
        return this.dataService.type === 'Certified Product';
      },
      isStratum() {
        return this.dataService.type === 'Stratum';
      },
      isSharedService() {
        return this.dataService.type === 'Shared Service';
      }
    },
    methods: {
      ...mapActions('serviceDesign', {
        setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
        removeInfraService: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE,
      }),
      addToDesign() {
        this.setInfraService({category: this.service, infraService: this.dataService});
        this.$notify('info', 'Info', `${this.dataService.displayName || this.dataService.serviceName} was added to your service design`);
      },
      removeFromServiceDesign() {
        this.removeInfraService({category: this.service, infraService: this.dataService});
        this.$notify('info', 'Info', `${this.dataService.displayName || this.dataService.serviceName} was removed from your service design`);
      },
      goToInfraService() {
        this.$router.push(`/infra-services/${this.dataService.id}`);
      }
    }
  }
</script>

<style lang="scss" scoped>
.infra-service {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  height: 70px;
  transition: all 0.5s;

  &.private {
    background-color: #e9f4ff;

    .action-block {
      background-color: #e9f4ff;
    }

    &:hover {
      background-color: #d8e8f9;

      .action-block {
        background-color: #d8e8f9;
      }
    }

    .icon-service {
      background-color: #428ce1;
    }

    .service-label {
      i {
        color: #428ce1;
      }
    }
  }

  &.public {
    background-color: #e3f9ed;

    .action-block {
      background-color: #e3f9ed;
    }

    &:hover {
      background-color: #caeedb;

      .action-block {
        background-color: #caeedb;
      }
    }

    .icon-service {
      background-color: #73e1a5;
    }

    .service-label {
      i {
        color: #73e1a5;
      }
    }
  }

  &.onprem {
    background-color: #eee6f8;

    .action-block {
      background-color: #eee6f8;
    }

    &:hover {
      background-color: #ded1f3;

      .action-block {
        background-color: #ded1f3;
      }
    }

    .icon-service {
      background-color: #a88cd1;
    }

    .service-label {
      i {
        color: #a88cd1;
      }
    }
  }

  &.na {
    background-color: #eff5c6;

    .action-block {
      background-color: #eff5c6;
    }

    &:hover {
      background-color: #dee6ae;

      .action-block {
        background-color: #dee6ae;
      }
    }

    .icon-service {
      background-color: #b3bb7e;
    }

    .service-label {
      i {
        color: #b3bb7e;
      }
    }
  }

  &.tool {
    background-color: #f4f0e6;

    .action-block {
      background-color: #f4f0e6;
    }

    &:hover {
      background-color: #e1dfcf;

      .action-block {
        background-color: #e1dfcf;
      }
    }

    .icon-service {
      background-color: #bfb9a7;
    }

    .service-label {
      i {
        color: #bfb9a7;
      }
    }
  }
}
.infra-service {
  width: 100%;
  overflow: hidden;

  &:hover {
    .action-block {
      right: 10px;
    }
    .btn-group {
      opacity: 1;
    }
  }
}
.info-block {
  max-width: 100%;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;

  .service-name {
    line-height: 1;
    font-weight: bold;
    sup {
      cursor: pointer;
    }
  }
}
.icon-service {
  display: flex;
  min-width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
}
.service-label {
  justify-self: flex-end;
}
.action-block {
  display: flex;
  align-items: center;
  position: absolute;
  right: -30px;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 5;
  padding-left: 10px;
  transition: all 0.5s;

  .btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left:5px;
    border-left: 1px solid $grey-6;
    margin-left: 15px;
    opacity: 0;
    transition: all 0.5s;
  }
}

</style>