<template>
  <div class="infra-service" :class="[`${dataService.cloudType ? dataService.cloudType.toLowerCase() : 'na'}`, { tool : dataService.type === 'Tool'}]">
    <div class="info-block">
      <span class="icon-service"></span>
      <span class="service-name">
        {{dataService.parentServiceDisplayName || dataService.displayName || dataService.serviceName}}
      </span>
    </div>
    <div class="action-block">
      <span class="service-label q-ml-auto">
        <q-icon v-if="isSharedService" name="share" size="11px"></q-icon>
        <q-icon v-if="isStratum" name="engp:engp-ico-stratum" size="11px" class="q-ml-xs"></q-icon>
        <q-icon v-if="isCertProduct" name="engp:engp-ico-cert_product" size="11px" class="q-ml-xs"></q-icon>
        <q-icon v-if="isPlanned" name="engp:engp-ico-planned" size="11px" class="q-ml-xs"></q-icon>
        <q-icon v-if="isDeprecated" name="engp:engp-ico-deprecated" size="11px" class="q-ml-xs"></q-icon>
      </span>
    </div>
    <div v-if="removable" class="remove" @click="remove">
      <q-icon name="delete" color="white" size="sm"></q-icon>
    </div>
  </div>
</template>

<script>

  export default {
    name: "TemplateInfraService",
    props: {
      dataService: {
        type: Object
      },
      removable: {
        type: Boolean,
        default: () => false,
      }
    },
    computed: {
      isSharedService() {
        return this.dataService.type === 'Shared Service';
      },
      isDeprecated() {
        return this.dataService.state === 'Deprecated';
      },
      isPlanned() {
        return this.dataService.state === 'Planned';
      },
      isCertProduct() {
        return this.dataService.type === 'Certified Product';
      },
      isStratum() {
        return this.dataService.type === 'Stratum';
      },
    },
    methods: {
      remove() {
        this.$emit('remove');
      }
    }
  }
</script>

<style lang="scss" scoped>
.infra-service {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 7px;


  .remove {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .remove {
      opacity: 1;
      visibility: visible;
    }
  }

  &.private {
    background-color: #e9f4ff;

    .action-block {
      background-color: #e9f4ff;
    }

    &:hover {
      background-color: #d8e8f9;

      .action-block {
        background-color: #d8e8f9;
      }
    }

    .icon-service {
      background-color: #428ce1;
    }

    .service-label {
      i {
        color: #428ce1;
      }
    }
  }

  &.public {
    background-color: #e3f9ed;

    .action-block {
      background-color: #e3f9ed;
    }

    &:hover {
      background-color: #caeedb;

      .action-block {
        background-color: #caeedb;
      }
    }

    .icon-service {
      background-color: #73e1a5;
    }

    .service-label {
      i {
        color: #73e1a5;
      }
    }
  }

  &.onprem {
    background-color: #eee6f8;

    .action-block {
      background-color: #eee6f8;
    }

    &:hover {
      background-color: #ded1f3;

      .action-block {
        background-color: #ded1f3;
      }
    }

    .icon-service {
      background-color: #a88cd1;
    }

    .service-label {
      i {
        color: #a88cd1;
      }
    }
  }

  &.na {
    background-color: #eff5c6;

    .action-block {
      background-color: #eff5c6;
    }

    &:hover {
      background-color: #dee6ae;

      .action-block {
        background-color: #dee6ae;
      }
    }

    .icon-service {
      background-color: #b3bb7e;
    }

    .service-label {
      i {
        color: #b3bb7e;
      }
    }
  }

  &.tool {
    background-color: #f4f0e6;

    .action-block {
      background-color: #f4f0e6;
    }

    &:hover {
      background-color: #e1dfcf;

      .action-block {
        background-color: #e1dfcf;
      }
    }

    .icon-service {
      background-color: #bfb9a7;
    }

    .service-label {
      i {
        color: #bfb9a7;
      }
    }
  }
}
.infra-service {
  width: 100%;
  overflow: hidden;

  &:hover {
    .action-block {
      right: 10px;
    }
    .btn-group {
      opacity: 1;
    }
  }
}
.info-block {
  max-width: 100%;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;

  .service-name {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
  }
}
.icon-service {
  display: flex;
  min-width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 50%;
  background-color: var(--q-primary);
}
.service-label {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}

</style>