
import {
  ControlElement,
} from '@jsonforms/core';
import {
  rendererProps,
  RendererProps,
  useJsonFormsEnumControl,
} from '@jsonforms/vue';
import { defineComponent } from 'vue';

const controlRenderer = defineComponent({
  name: 'enum-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
    manifest: {type: Object, default: () => ({})},
    isNewEl: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsEnumControl(props);
  },
  data() {
    return {
      disable: false,
    }
  },
  mounted() {
    if (!this.isNewEl) {
      this.disable = this.control.data && this.control.uischema.options && !this.control.uischema.options.editable ? true : false;
    }
    if (!this.control.data && this.control.uischema.options?.default) {
      this.control.data = this.control.uischema.options.default
    }
    this.onChange(this.control.data);
  },
  computed: {
    options() {
      const key: any = this.control.uischema.options && this.control.uischema.options.key ? this.control.uischema.options.key : null;
      const options: any[] = [];
      this.control.options.forEach((item: any) => {
        if (Object.keys(this.manifest).length && this.control.uischema.options && this.control.uischema.options.unique && this.control.uischema.options.key) {
          const keys = this.manifest[key].map((item: any) => item.id);
          if (keys.includes(item.value)) {
            item.disable = true;
          }
        }
        if (this.control.uischema.options && this.control.uischema.options.disabledOptions && this.control.uischema.options.disabledOptions.includes(item.value)) {
          item.disable = true;
        }
        options.push(item)
      });
      return options;
    },
    fieldRules() {
      const rules = [];
      if (this.control.required) {
        rules.push((val: string) => !!val || 'Field is required');
      }
      if (this.control.schema.pattern) {
        const pattern = new RegExp(this.control.schema.pattern);
        rules.push((v: any) => pattern.test(v) || 'No valid value')
      }
      return rules;
    }
  },
  methods: {
    onChange(value: any) {
      this.handleChange(
        this.control.path,
        value
      );
    }
  },
});

export default controlRenderer;
