
import { v4 as uuidv4 } from "uuid";
import {
  ControlElement,
  composePaths,
  createDefaultValue,
  UISchemaElement,
  findUISchema,
  Resolve,
  JsonSchema,
  getFirstPrimitiveProp, getControlPath
} from "@jsonforms/core";
import { defineComponent, ref } from 'vue';
import {
  DispatchRenderer,
  rendererProps,
  useJsonFormsArrayControl,
  RendererProps,
} from '@jsonforms/vue';
import { useNested } from "../util";

const controlRenderer = defineComponent({
  name: 'custom-zones-layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
    manifest: {type: Object, default: () => ({})},
    freezeManifest: {type: Object, default: () => ({})},
    currentEnv: {type:String, default: () => ''},
    cloudentePermissions: {type: Object},
  },
  setup(props: RendererProps<ControlElement>) {
    const control = useJsonFormsArrayControl(props);
    const page = ref<number>(1);
    const showFilters = ref<boolean>(false);
    const showZonesCount = ref<number>(5);
    const suggestToDelete = ref<null | number>(null);
    const zonesFilters = ref<any>({
      name: undefined,
      license: undefined,
      DNS: undefined,
    })
    useNested('array');
    return {
      ...control,
      suggestToDelete,
      page,
      showZonesCount,
      zonesFilters,
      showFilters,
    };
  },
  computed: {
    filteredZones() {
      if (this.control.data?.length) {
        return Object.values(this.control?.data).filter((zone: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.zonesFilters) {
            if (this.zonesFilters[prop]) {
              if (prop === 'DNS') {
                if (this.zonesFilters.DNS === 'disabled') {
                  if (zone.DNS) {
                    return false
                  }
                }
                if (this.zonesFilters.DNS === 'enabled') {
                  if (!zone.DNS) {
                    return false
                  }
                }
              } else {
                if (!new RegExp(this.zonesFilters[prop], 'i').test(zone[prop])) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      } else {
        return []
      }
    },
    showingZones() {
      return this.filteredZones?.length ? JSON.parse(JSON.stringify(this.filteredZones)).splice((this.page -1) * this.showZonesCount, this.showZonesCount) : [];
    },
    pages() {
      return Math.ceil(this.filteredZones.length/this.showZonesCount);
    },
    countItems() {
      return this.control.data.length;
    },
    addDisabled(): boolean {
      return (
        !this.cloudentePermissions?.zonesCreate ||
        !this.control.enabled ||
        (
          this.arraySchema !== undefined &&
          this.arraySchema.maxItems !== undefined &&
          this.dataLength >= this.arraySchema.maxItems)
      );
    },
    dataLength(): number {
      return this.control.data ? this.control.data.length : 0;
    },
    foundUISchema(): UISchemaElement {
      return findUISchema(
        this.control.uischemas,
        this.control.schema,
        this.control.uischema.scope,
        this.control.path,
        undefined,
        this.control.uischema,
        this.control.rootSchema
      );
    },
    arraySchema(): JsonSchema | undefined {
      return Resolve.schema(
        this.control.rootSchema,
        this.control.uischema.scope,
        this.control.rootSchema
      );
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema)
      )();
      this.control.data[this.control.data.length - 1].id = uuidv4();
      this.control.data[this.control.data.length - 1].isNewEl = true;
      this.page = this.pages;
    },
    showFilter() {
      this.showFilters = !this.showFilters;
    },
    resetFilters() {
      this.zonesFilters.name = undefined;
      this.zonesFilters.license = undefined;
      this.zonesFilters.DNS = undefined;
    },
    elIndex(el: any) {
      return this.control.data.findIndex((a: any) => a.id === el.id);
    },
    removeItemsClick(event: Event, toDelete: number[]): void {
      event.stopPropagation();
      this.removeItems?.(this.control.path, toDelete)();
    },
    childLabelForIndex(index: number | null) {
      if (index === null) {
        return '';
      }
      const childLabelProp =
        this.control.uischema.options?.childLabelProp ??
        getFirstPrimitiveProp(this.control.schema);
      if (!childLabelProp) {
        return `${index}`;
      }
      const labelValue = Resolve.data(
        this.control.data,
        composePaths(`${index}`, childLabelProp)
      );
      if (
        labelValue === undefined ||
        labelValue === null ||
        Number.isNaN(labelValue)
      ) {
        return '';
      }
      return `${labelValue}`;
    },
    canBeDelete(element: any) {
      return element.markDeleted || (!element.isNewEl && !this.cloudentePermissions?.zonesDelete);
    },
    childErrors(index: number) {
      return this.control.childErrors.filter((e) => {
        const errorDataPath = getControlPath(e);
        const splittedErrorPath = errorDataPath.split('.');
        const originErrorPath = [splittedErrorPath[0], splittedErrorPath[1]].join('.');
        return originErrorPath == this.composePaths(this.control.path, `${index}`);
      });
    },
  },
});

export default controlRenderer;
