export const SERVICE_DESIGN_GETTERS = {
  ALL: 'design',
};

export const SERVICE_DESIGN_ACTIONS = {
  SET_SERVICES: 'setServices',
  SET_INFRASERVICE: 'setInfraservice',
  REMOVE_INFRASERVICE: 'removeInfraService',
  RESET_SERVICE_DESIGN: 'resetServiceDesign',
}

const SERVICE_DESIGN_MUTATIONS = {
  SET_SERVICES: 'setServices',
  SET_INFRASERVICE: 'setInfraservice',
  REMOVE_INFRASERVICE: 'removeInfraService',
}

export default {
  namespaced: true,
  state: {
    design: []
  },
  getters: {
    [SERVICE_DESIGN_GETTERS.ALL]: (state: any) => {
      return state.design;
    },
  },
  mutations: {
    [SERVICE_DESIGN_MUTATIONS.SET_SERVICES]: (state: any, services: any) => {
      const servicesObject = services.map((service: any) => ({
        categoryId: service.categoryId,
        name: service.name,
        infraServices: []
      }));
      state.design = servicesObject;
    },
    [SERVICE_DESIGN_MUTATIONS.SET_INFRASERVICE]: (state: any, data: any) => {
      const category = state.design.find((category: any) => category.categoryId === data.category.categoryId);
      category && category.infraServices ? category.infraServices.push(data.infraService) : false;
    },
    [SERVICE_DESIGN_MUTATIONS.REMOVE_INFRASERVICE]: (state: any, data: any) => {
      const category = state.design.find((category: any) => category.categoryId === data.category.categoryId);
      const index = category.infraServices.findIndex((infraService: any) => infraService.id === data.infraService.id);
      category.infraServices.splice(index, 1);
    },
  },
  actions: {
    [SERVICE_DESIGN_ACTIONS.SET_SERVICES]: async (context: any, services: any) => {
      if (!services.length) {
        context.commit(SERVICE_DESIGN_MUTATIONS.SET_SERVICES, context.rootState.services.catalogServices);
      } else {
        context.commit(SERVICE_DESIGN_MUTATIONS.SET_SERVICES, services);
      }
    },
    [SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE]: async (context: any, data: any) => {
      context.commit(SERVICE_DESIGN_MUTATIONS.SET_INFRASERVICE, data);
    },
    [SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE]: async (context: any, data: any) => {
      context.commit(SERVICE_DESIGN_MUTATIONS.REMOVE_INFRASERVICE, data);
    },
    [SERVICE_DESIGN_ACTIONS.RESET_SERVICE_DESIGN]: async (context: any) => {
      context.commit(SERVICE_DESIGN_MUTATIONS.SET_SERVICES, context.rootState.services.catalogServices);
    }
  }
}