<template>
  <q-page v-if="serviceView === 'wizard'" class="q-px-lg main-wrapper-page single-page" style="background-color: var(--q-accent);">
    <div class="catalog-legend">
      <q-scroll-area
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', height: '3px', opacity: 1 }"
          style="height: 30px; max-width: 100%;"
      >
        <div class="row-legend">
          <div class="col types">
            <span>Service Types:</span>
            <ul>
              <li class="private">
                <span></span>
                Private Cloud
              </li>
              <li class="public">
                <span></span>
                Public Clouds
              </li>
              <li class="onprem">
                <span></span>
                On Prem
              </li>
              <li class="tool">
                <span></span>
                Tools
              </li>
              <li class="independent">
                <span></span>
                Independent
              </li>
            </ul>
          </div>
          <div class="col attributes">
            <span>Attributes:</span>
            <ul>
              <li>
                <q-icon name="engp:engp-ico-paved_road" size="xs"/>
                Paved Road
              </li>
              <li>
                <q-icon name="engp:engp-ico-stratum" size="xs"/>
                Stratum
              </li>
              <li>
                <q-icon name="engp:engp-ico-cert_product" size="xs"/>
                Certified Product
              </li>
              <li>
                <q-icon name="engp:engp-ico-planned" size="xs"/>
                Planned
              </li>
              <li>
                <q-icon name="engp:engp-ico-deprecated" size="xs"/>
                Not recommended
              </li>
            </ul>
          </div>
        </div>
      </q-scroll-area>
    </div>
    <div class="row no-wrap">
      <div class="col col-12 q-px-md content-column">
        <transition
          appear
          :enter-active-class="animatedDirection === 'up' ? fadeInUp : fadeInDown"
          :leave-active-class="animatedDirection === 'up' ? fadeOutUp : fadeOutDown"
        >
          <div v-if="show" class="wizard-holder" :style="{ minWidth: minWidth + 'px', maxWidth: maxWidth}">
            <div class="heading-wizard" v-if="showBlock.head.length" :class="{childrenHasLine: showBlock.head.length > 1}">
              <span class="shadow"></span>
              <span class="heading-line" v-if="showBlock.head.length > 1" :style="{maxWidth: headLineWidth + 'px'}">
                <span v-if="isPavedRoadHeadingLine" class="paved-road-line" :style="{width: pavedRoadHeadLineWidth}"></span>
              </span>
              <div
                class="wizard-item"
                :class="{pavedRoad : item.partOfPavedRoad, firstHead: index === 0, lastHead: index === showBlock.head.length - 1}"
                v-for="(item, index) in showBlock.head"
                :key="item.id"
                @click="prev(item)"
              >
                <img class="back" :src="$options.back" alt="">
                <div class="content-label" v-html="markDownContent(item.shortLabel || item.label)"></div>
              </div>
            </div>
            <div class="wizard-middle" :class="{'heading-wizard' : !showBlock.head.length, oneChild: showBlock.childrenObjects.length === 1}">
              <wizard-item
                :design="design"
                :service="service"
                :class="{
                  pavedRoad : showBlock.middle.partOfPavedRoad || !!showBlock.childrenObjects.find(item => item.partOfPavedRoad),
                  headItem: showBlock.middle.id === jsonData[0].id,
                  usecase: showBlock.middle.type === 'usecase',
                  service: showBlock.middle.type === 'service'
                }"
                :headBlock="!!showBlock.head.length"
                :item-data="showBlock.middle"
                :current-step="showBlock.middle.id"
              />
            </div>
            <div class="children-items" :class="{hasOneChild: showBlock.childrenObjects.length === 1, hasManyChild: showBlock.lastWays.length}">
              <span v-if="showBlock.childrenObjects.length && showBlock.childrenObjects.length != 1 && showBlock.childrenObjects.length < 6" class="children-line">
                 <span v-if="isPavedRoadItem" class="paved-road-line" :style="{width: pavedRoadLineWidth}">
                   <span class="paved-road-label">Paved Road</span>
                 </span>
              </span>
              <div class="many-services" :class="{pavedRoad : showBlock.middle.partOfPavedRoad}" v-if="showBlock.childrenObjects.length > 5">
                <span class="items-border border-top"></span>
                <span class="items-border border-bottom"></span>
                <wizard-item
                  :design="design"
                  :service="service"
                  :current-step="showBlock.middle.id"
                  :class="{
                    pavedRoad : item.partOfPavedRoad,
                    hasChildren: item.out.length,
                    lastStep: !item.out.length,
                    usecase: item.type === 'usecase',
                    service: item.type === 'service'
                  }"
                  v-for="item in showBlock.childrenObjects"
                  :key="item.id"
                  @click="next(item)"
                  :itemData="item"
                />
              </div>
              <wizard-item
                v-else
                :design="design"
                :service="service"
                :current-step="showBlock.middle.id"
                :class="{
                  pavedRoad : item.partOfPavedRoad,
                  hasChildren: item.out.length,
                  lastStep: !item.out.length,
                  usecase: item.type === 'usecase',
                  service: item.type === 'service'
                }"
                v-for="item in showBlock.childrenObjects"
                :key="item.id"
                @click="next(item)"
                :itemData="item"
              />
            </div>
            <div v-if="showBlock.lastWays && showBlock.lastWays.length > 0" class="last-ways">
              <div v-for="item in showBlock.lastWays" :key="item.id" class="last-way-item" :class="{pavedRoad: item.partOfPavedRoad}" @click="next(item)">
                <div class="content-label" v-html="markDownContent(item.shortLabel || item.label)"></div>
                <img class="back" :src="$options.back" alt="">
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </q-page>

  <div v-else class="decision-tree" style="background-color: var(--q-accent)">
    <div class="legend">
      <ul>
        <!--<li>  <svg class="svg-triangle'" width="15" height="15">
          <path stroke-width="6" stroke="#7db3ef" d="M 8,8 8.5,8.3 8,8.6 z"></path>
          <path stroke-width="3" stroke="white" d="M 8,8 8.5,8.3 8,8.6 z"></path>
        </svg> Use Case, Paved Road</li>-->
        <li>  <svg class="svg-triangle'" width="15" height="15">
          <path stroke-width="6" stroke="grey" d="M 8,8 8.5,8.3 8,8.6 z"></path>
          <path stroke-width="3" stroke="white" d="M 8,8 8.5,8.3 8,8.6 z"></path>
        </svg> Use Case</li>
        <!--<li><span class="round paved"></span>Service, Paved Road</li>-->
        <li><span class="round service"></span>Service</li>
      </ul>
    </div>
    <div v-if="load" class="tree-wrapper" v-html="renderChart()">
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import _ from "lodash";
import {marked} from "marked";
import apollo from "@/library/http/apollo";
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import emitter from "tiny-emitter/instance";
import back from '../../assets/back.svg';
import WizardItem from "@/pages/services/components/WizardItem";
import { SERVICE_DESIGN_GETTERS } from "@/store/modules/service-design";

const background_color = "#f4f5f9";
export default {
  name: "TreeWizard",
  components: {WizardItem},
  back,
  computed: {
    ...mapGetters('serviceDesign', {
      design: SERVICE_DESIGN_GETTERS.ALL
    }),
    pavedRoadLineWidth() {
      let width = 'calc(50% + 25px)';
      const pavedRoadItems = this.showBlock.childrenObjects.filter(item => item.partOfPavedRoad);
      const c = pavedRoadItems.length / this.showBlock.childrenObjects.length;
      if (this.showBlock.childrenObjects.length > 3 && pavedRoadItems.length > 2) {
        width = `calc(${c * 100}% - 90px)`;
      }
      if (pavedRoadItems.length === 5 || pavedRoadItems.length === this.showBlock.childrenObjects.length) {
        width = '100%';
      }
      return width;
    },
    headLineWidth() {
      return ((this.showBlock.head.length - 1) * 280) - 50;
    },
    isPavedRoadItem() {
      return !!this.showBlock.childrenObjects.find(item => item.partOfPavedRoad)
    },
    isPavedRoadHeadingLine() {
      return !!this.showBlock.head.find(item => item.partOfPavedRoad) && this.showBlock.head.length > 1;
    },
    pavedRoadHeadLineWidth() {
      let width = 'calc(50% + 25px)';
      const pavedRoadItems = this.showBlock.head.filter(item => item.partOfPavedRoad);
      if (pavedRoadItems.length === this.showBlock.head.length) {
        width = 'calc(100% + 25px)';
      }
      return width;
    },
    maxWidth() {
      let defaultMaxWidth = '100%';
      if (this.showBlock.childrenObjects.length === 2) {
        defaultMaxWidth = '860px';
      }
      if (this.showBlock.childrenObjects.length === 3) {
        defaultMaxWidth = '1024px';
      }
      return defaultMaxWidth;
    },
    minWidth() {
      let defaultMinWidth;
      if(this.showBlock.childrenObjects.length === 2) {
        defaultMinWidth = '600'
      } else if (this.showBlock.childrenObjects.length === 3) {
        defaultMinWidth = '1080'
      } else if (this.showBlock.childrenObjects.length === 4) {
        defaultMinWidth = '1360'
      } else {
        defaultMinWidth = '1080'
      }
      return defaultMinWidth;
    },
    fadeInUp() {
      return 'animated fadeInUp';
    },
    fadeOutUp() {
      return 'animated fadeOutUp';
    },
    fadeInDown() {
      return 'animated fadeInDown';
    },
    fadeOutDown() {
      return 'animated fadeOutDown';
    },
  },
  methods: {
    async getData() {
      try {
        await apollo.apolloClient.query({
          query: gql`
          query {
            getSupportingDecisionCategories(category: "${this.$route.params.id}") {
              id
              label
              shortLabel
              parents
              pavedRoad
              type
            }
          }
        `
        }).then(response => {
          this.treeData = response.data.getSupportingDecisionCategories;
        })
      } catch (error) {
        this.$notify('negative', 'Error', error.message)
      } finally {
        this.load = true;
      }
    },
    renderChart(){
      let options = {};
      const tangleLayout = this.constructTangleLayout(_.cloneDeep(this.treeData), options);
      const rawSvg =
      `<svg width="${tangleLayout.layout.width + 130}" height="${tangleLayout.layout.height}" style="background-color: ${background_color}">
        <style>
          text {
            font-size: 11px;
          }
          .node {
            stroke-linecap: round;
          }
          .link {
            fill: none;
          }
        </style>
        ${tangleLayout.bundles.map((b, i) => {
          let d = b.links.map(l => `
            M${l.xt} ${l.yt}
            L${l.xb - l.c1} ${l.yt}
            A${l.c1} ${l.c1} 90 0 1 ${l.xb} ${l.yt + l.c1}
            L${l.xb} ${l.ys - l.c2}
            A${l.c2} ${l.c2} 90 0 0 ${l.xb + l.c2} ${l.ys}
            L${l.xs} ${l.ys}`).join("");
          return `
            <path class="link" d="${d}" stroke="${background_color}" stroke-width="5"/>
            <path class="link" d="${d}" stroke="#d2d8df" stroke-width="2"/>
          `;
          })}
          ${tangleLayout.nodes.map(n => {
            const defaultPath = `
              <path class="node" data-id="${n.id}" stroke="${n.pavedRoad ? '#7db3ef' : '#56c88a'}" stroke-width="8" d="M${n.x} ${n.y - n.height / 2} L${n.x} ${n.y + n.height / 2}"/>
              <path class="node" stroke="white" stroke-width="4" d="M${n.x} ${n.y - n.height / 2} L${n.x} ${n.y + n.height / 2}"/>
              <text x="${n.x + 4}" y="${n.y - n.height / 2 - 4}" style="pointer-events: none; font-weight: ${n.type === 'service' ? 'bold' : 'normal'}">${n.shortLabel || n.label}</text>
            `;
            const trianglePath = `
                <path stroke-width="6" stroke="${n.pavedRoad ? '#7db3ef' : 'grey'}" d="M ${n.x},${n.y + 0.2} ${n.x + 0.5},${n.y + 0.5} ${n.x},${n.y + 0.8} z"/>
                <path stroke="white" stroke-width="2"d="M ${n.x},${n.y + 0.2} ${n.x + 0.5},${n.y + 0.5} ${n.x},${n.y + 0.8} z"/>
                <text fill="${n.pavedRoad ? '#7db3ef' : '#555555'}" style="font-weight: ${n.pavedRoad ? 'bold' : 'normal'}"" x="${n.x + 4}" y="${n.y - n.height / 2 - 4}" style="pointer-events: none; font-weight: ${n.type === 'service' ? 'bold' : 'normal'}">${n.shortLabel || n.label}</text>
            `;
            return n.type === 'service' ? defaultPath : trianglePath;
          }
        )}
      </svg>`;
      return rawSvg
    },
    constructTangleLayout(levels, options={}) {
      // precompute level depth
      levels.forEach((l, i) => l.forEach(n => (n.level = i)));

      var nodes = levels.reduce((a, x) => a.concat(x), []);
      var nodes_index = {};
      nodes.forEach(d => (nodes_index[d.id] = d));

      // objectification
      nodes.forEach(d => {
        d.parents = (d.parents === undefined ? [] : d.parents).map(
            p => nodes_index[p]
        );
      });

      // precompute bundles
      levels.forEach((l, i) => {
        var index = {};
        l.forEach(n => {
          if (n.parents.length == 0) {
            return;
          }

          var id = n.parents
              .map(d => d.id)
              .sort()
              .join('-X-');
          if (id in index) {
            index[id].parents = index[id].parents.concat(n.parents);
          } else {
            index[id] = { id: id, parents: n.parents.slice(), level: i, span: i - d3.min(n.parents, p => p.level) };
          }
          n.bundle = index[id];
        });
        l.bundles = Object.keys(index).map(k => index[k]);
        l.bundles.forEach((b, i) => (b.i = i));
      });

      var links = [];
      nodes.forEach(d => {
        d.parents.forEach(p => {
              links.push({source: d, bundle: d.bundle, target: p})
            }
        );
      });

      var bundles = levels.reduce((a, x) => a.concat(x.bundles), []);

      // reverse pointer from parent to bundles
      bundles.forEach(b =>
        b.parents.forEach(p => {
          if (p.bundles_index === undefined) {
            p.bundles_index = {};
          }
          if (!(b.id in p.bundles_index)) {
            p.bundles_index[b.id] = [];
          }
          p.bundles_index[b.id].push(b);
        })
      );

      nodes.forEach(n => {
        if (n.bundles_index !== undefined) {
          n.bundles = Object.keys(n.bundles_index).map(k => n.bundles_index[k]);
        } else {
          n.bundles_index = {};
          n.bundles = [];
        }
        n.bundles.sort((a,b) => d3.descending(d3.max(a, d => d.span), d3.max(b, d => d.span)))
        n.bundles.forEach((b, i) => (b.i = i));
      });

      links.forEach(l => {
        if (l.bundle.links === undefined) {
          l.bundle.links = [];
        }
        l.bundle.links.push(l);
      });

      // layout
      const padding = 8;
      const node_height = 25;
      const node_width = 130;
      const bundle_width = 30;
      const level_y_padding = 16;
      const metro_d = 4;
      const min_family_height = 22;

      options.c ||= 16;
      const c = options.c;
      options.bigc ||= node_width+c;

      nodes.forEach(
          n => (n.height = (Math.max(1, n.bundles.length) - 1) * metro_d)
      );

      var x_offset = padding;
      var y_offset = padding;
      levels.forEach(l => {
        x_offset += l.bundles.length * bundle_width;
        y_offset += level_y_padding;
        l.forEach((n, i) => {
          n.x = n.level * node_width + x_offset;
          n.y = node_height + y_offset + n.height / 2;

          y_offset += node_height + n.height;
        });
      });

      var i = 0;
      levels.forEach(l => {
        l.bundles.forEach(b => {
          b.x =
            d3.max(b.parents, d => d.x) +
            node_width +
            (l.bundles.length - 1 - b.i) * bundle_width;
          b.y = i * node_height;
        });
        i += l.length;
      });

      links.forEach(l => {
        l.xt = l.target.x;
        l.yt =
          l.target.y +
          l.target.bundles_index[l.bundle.id].i * metro_d -
          (l.target.bundles.length * metro_d) / 2 +
          metro_d / 2;
        l.xb = l.bundle.x;
        l.yb = l.bundle.y;
        l.xs = l.source.x;
        l.ys = l.source.y;
      });

      // compress vertical space
      var y_negative_offset = 0;
      levels.forEach(l => {
        y_negative_offset +=
          -min_family_height +
          d3.min(l.bundles, b =>
            d3.min(b.links, link => link.ys - 2*c - (link.yt + c))
          ) || 0;
        l.forEach(n => (n.y -= y_negative_offset));
      });

      // very ugly, I know
      links.forEach(l => {
        l.yt =
          l.target.y +
          l.target.bundles_index[l.bundle.id].i * metro_d -
          (l.target.bundles.length * metro_d) / 2 +
          metro_d / 2;
        l.ys = l.source.y;
        l.c1 = l.source.level - l.target.level > 1 ? Math.min(options.bigc, l.xb-l.xt, l.yb-l.yt)-c : c;
        l.c2 = c;
      });

      var layout = {
        width: d3.max(nodes, n => n.x) + node_width + 2 * padding,
        height: d3.max(nodes, n => n.y) + node_height / 2 + 2 * padding,
        node_height,
        node_width,
        bundle_width,
        level_y_padding,
        metro_d
      };

      return { levels, nodes, nodes_index, links, bundles, layout };
    },
    markDownContent(data) {
      return data ? marked(data) : 'Missed label';
    },
    async getDecisionTreeData() {
      try {
        await apollo.apolloClient.query({
          query: gql`
          query {
            getActualDecisionTreeRelationsByCategory(category: "${this.$route.params.id}") {
            id
            label
            shortLabel
            partOfPavedRoad
            type
            hint {
              avoid
              follow
            }
            serviceData {
              id
              apmid
              icon
              description
              orderable
              displayName
              pavedRoad
              serviceName
              cloudType
              state
              type
            }
            in {
              id
              label
              shortLabel
              partOfPavedRoad
              type
              serviceData {
                id
                apmid
                icon
                description
                orderable
                displayName
                pavedRoad
                serviceName
                cloudType
                state
                type
              }
              hint {
                avoid
                follow
              }
            }
            out {
              id
              label
              shortLabel
              partOfPavedRoad
              type
              serviceData {
                id
                icon
                apmid
                description
                orderable
                displayName
                cloudType
                pavedRoad
                serviceName
                state
                type
              }
              hint {
                avoid
                follow
              }
            }
           }
          }
        `
        }).then(response => {
          const data = response.data.getActualDecisionTreeRelationsByCategory;
          this.jsonData = data;
        })
      } catch (error) {
        this.$notify('negative', 'Error', error.message)
      }
    },
    async getCategoryData() {
      try {
        await apollo.apolloClient.query({
          query: gql`
          query {
            categoryServicesById(category: "${this.$route.params.id}") {
              name
              categoryId
            }
          }
        `
        }).then(response => {
          this.service = response.data.categoryServicesById;
        })
      } catch (error) {
        this.$notify('negative', 'Error', error.message)
      }
    },
    next(item) {
      if (item.out && item.out.length) {
        this.animatedDirection = 'up';
        this.show = false;
        setTimeout(() => {
          this.nextStep(item.id)
        }, 550)
      }
    },
    prev(item) {
      if (item.in.length || item.id === this.jsonData[0].id) {
        this.animatedDirection = 'down';
        this.show = false;
        setTimeout(() => {
          this.nextStep(item.id)
        }, 550)
      }
    },
    nextStep(id) {
      this.showBlock.middle = this.jsonData.find(item => item.id === id);
      if (this.showBlock.middle.id === this.jsonData[0].id) {
        this.showBlock.head = [];
      }
      if (this.showBlock.middle.out && this.showBlock.middle.out.length) {
        const children = [];
        const childrenIds = this.showBlock.middle.out.map(item => item.id);
        this.jsonData.forEach(item => {
          if (childrenIds.includes(item.id)) {
            children.push(item);
          }
        });
        this.showBlock.childrenObjects = children.sort((a,b) => {
          if ((Number(a.partOfPavedRoad ? a.partOfPavedRoad : null), Number(b.partOfPavedRoad ? b.partOfPavedRoad : null))) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      if (this.showBlock.childrenObjects.length > 5 && this.showBlock.head.length ) {
        const lastWays = [];
        this.showBlock.childrenObjects.forEach(item => {
         if (item.type === 'usecase') {
           lastWays.push(item)
           const index = this.showBlock.childrenObjects.findIndex(item => item.type === 'usecase')
           this.showBlock.childrenObjects.splice(index, 1)
         }
       })
        this.showBlock.lastWays = lastWays;
      } else {
        this.showBlock.lastWays = [];
      }
      if (this.showBlock.middle.in && this.showBlock.middle.in.length) {
        const parents = [];
        const parentIds = this.showBlock.middle.in.map(item => item.id);
        this.jsonData.forEach(item => {
          if (parentIds.includes(item.id)) {
            parents.push(item);
          }
        });
        this.showBlock.head = parents.sort((a,b) => {
          if ((Number(a.partOfPavedRoad ? a.partOfPavedRoad : null), Number(b.partOfPavedRoad ? b.partOfPavedRoad : null))) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      this.show = true;
    },
  },
  async mounted() {
    this.serviceView = this.$route.params.pageView ? this.$route.params.pageView : 'wizard';
    this.breadcrumbs = this.$route.meta.breadcrumbs;
    this.$showLoading();
    try {
      await this.getCategoryData();
      await this.getDecisionTreeData();
      await this.getData();
    } catch (error) {
      this.$notify('negative', 'Error', error.message)
    } finally {
      this.$hideLoading();
      this.show = true;
      this.load = true;
    }
    if (this.$route.params.currentStep) {
      this.nextStep(this.$route.params.currentStep);
    } else {
      this.showBlock.middle = this.jsonData[0];
    }
    const children = [];
    const childrenIds = this.showBlock.middle.out.map(item => item.id);
    this.jsonData.forEach(item => {
      if (childrenIds.includes(item.id)) {
        children.push(item);
      }
    });
    this.showBlock.childrenObjects = children.sort((a,b) => {
      if ((Number(a.partOfPavedRoad ? a.partOfPavedRoad : null), Number(b.partOfPavedRoad ? b.partOfPavedRoad : null))) {
        return 1;
      } else {
        return -1;
      }
    });
  },
  created() {
    emitter.on('changeServiceViewPage', (view) => {
      this.serviceView = view;
    });
  },
  unmounted() {
    emitter.off('changeServiceViewPage');
  },
  data() {
    return {
      animatedDirection: 'up',
      show: false,
      serviceView: 'wizard',
      service: {},
      breadcrumbs: [],
      showBlock: {
        head: [],
        middle: {},
        childrenObjects: [],
        lastWays: []
      },
      jsonData: [],
      load: false,
      treeData: []
    }
  }
}
</script>

<style lang="scss">

.many-services {
  position: relative;
  max-width: 930px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  .items-border {
    display: block;
    width: 500px;
    height: 10px;
    border-top: 2px solid #d9dcee;
    border-radius: 10px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);

    &.border-bottom {
      bottom: -20px;
      top: auto;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  .shadow {
    display: block;
    position: absolute;
    bottom: auto;
    left: 0;
    right: 0;
    top: -90px;
    height: 90px;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box !important;
    z-index: 7;
    transform: rotate(180deg);
  }

  &:before {
    content: '';
    width: 50px;
    height: 116px;
    background-color: #d9dcee;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -135px;
    z-index: 1;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }

  &.pavedRoad {
    &:before {
      background: #A7CEFB;
    }
  }

  .wizard-item {
    margin: 15px;
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

.content-label {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 10px;
  line-height: 1.15;
  text-align: center;
}

.content-wizard-item {
  padding: 10px;
}

.content-column {
  height: calc(100vh - 98px);
  overflow-y: hidden;
  overflow-x: auto;
}

.wizard-holder {
  min-width: 1080px;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  animation-duration: 1s;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: rgb(216,232,249);
    transform: rotate(180deg);
    background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box;
    z-index: 4;
  }
}

.paved-road-line {
  height: 50px;
  background: #A7CEFB;
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  z-index: 5;
}

.children-items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 150px;

  &.hasOneChild {
    justify-content: center;

    .wizard-item {
       &:before {
         height: 100px;
         top: -100px;
         border-radius: 0;
       }
    }
  }

  .children-line {
    height: 50px;
    width: calc(100% - 230px);
    background-color: #d9dcee;
    position: absolute;
    left: 115px;
    top: -135px;
    border-radius: 25px;
  }

  &.hasManyChild {
    padding-bottom: 0;
  }
}

.heading-wizard.wizard-middle {
  .wizard-item {
    &:after {
      display: block;
    }
  }
}

.paved-road-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px;
  color: #7AA4D5;
}

.heading-wizard {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 130px;

  .shadow {
    display:  block;
    position: absolute;
    bottom: -90px;
    left: 0;
    right: 0;
    top: auto;
    height: 90px;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box !important;
    z-index: 7;
  }

  &.childrenHasLine {
    margin-bottom: 210px;

    .wizard-item {
      text-align: center;

      &.firstHead {
        &:before {
          border-bottom-left-radius: 25px;
        }
      }

      &.lastHead {
        &:before {
          border-bottom-right-radius: 25px;
        }
      }

      &:before {
        content: '';
        width: 50px;
        height: 130px;
        background-color: #d9dcee !important;
        background: #d9dcee !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -130px;
      }
    }
  }

  .heading-line {
    height: 50px;
    width: 100%;
    position: absolute;
    background: #dadcee;
    bottom: -130px;
    z-index: 6;

    .paved-road-line {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .wizard-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
    background-color: transparent;

    &.headItem {
      &:before {
        display: none;
      }
    }

    &:after {
      display: none;
    }

    .back {
      transition: all 0.4s;
    }

    &:hover {
      .back {
        transform: translateY(-10px);
      }
    }

    img {
      margin-bottom: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      right: 0;
      top: auto;
      height: 30px;
      border-radius: 0;
      background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box !important;
      z-index: 4;
    }
  }
}

.wizard-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 60px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  border-radius: 40px;
  transition: all 0.4s;
  z-index: 1;

  p {
    margin: 0;
  }

  &.lastStep {
    &:after {
      display: none;
    }
  }

  &.pavedRoad {
    &:before,
    &:after {
      background: #A7CEFB;
    }
  }

  &:hover {
    .border {
      width: 310px;
      height: 90px;
      border-radius: 50px;
    }
  }

  .border {
    width: 300px;
    position: absolute;
    height: 80px;
    border-radius: 40px;
    background: #f4f5f982;
    box-shadow: 0px 20px 40px #3C415026;
    z-index: -1;
    transition: all 0.5s;
  }

  &:before {
    content: '';
    width: 50px;
    height: 135px;
    background-color: #d9dcee;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -135px;
    z-index: -1;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }

  &:after {
    content: "";
    width: 50px;
    height: 125px;
    background-color: #d9dcee;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -125px;
    z-index: -2;
  }

  &.first-item {
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 135px;

    &.pavedRoad {
      &:after {
        background: #A7CEFB;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }

    &:after {
      content: "";
      width: 50px;
      height: 125px;
      background-color: #d9dcee;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -115px;
      z-index: -1;
    }

    &:before {
      display: none;
    }
  }

  &.first {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    img {
      margin-bottom: 5px;
    }
  }
}

.wizard-middle {
  position: relative;
  z-index: 5;
  margin-bottom: 210px;
  display: flex;
  justify-content: center;

  &.oneChild {
    margin-bottom: 100px;

    .wizard-item {
      &:after {
        height: 0;
      }
    }
  }

  .shadow {
    display:  block;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    top: auto;
    height: 90px;
    border-radius: 0;
    background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box !important;
    z-index: 4;
  }

  .wizard-item {

    &:before {
      height: 130px;
      top: -130px;
      border-radius: 0;
    }

    &.pavedRoad {
      &:after {
        z-index: -2;
        border-bottom-right-radius: 25px;
      }
    }
  }

  &.heading-wizard {

    &:after {
      display: block !important;
    }

    .wizard-item {
      display: flex;
      width: auto;
      flex-direction: column;
      justify-content: center;
      background-color: transparent;
      height: 145px;
      font-size: 16px;
      font-weight: bold;

      .shadow {
        display:  block;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        top: auto;
        height: 30px;
        border-radius: 0;
        background: transparent linear-gradient(180deg, #F4F5F9 0%, #F4F5F9 26%, #F4F5F900 100%) 0% 0% no-repeat padding-box !important;
        z-index: 4;
      }

      img {
        margin-bottom: 10px;
      }
    }
  }
}

.heading-wizard {

  .wizard-item {
    &.pavedRoad {
      &:before {
        background: #A7CEFB !important;
      }
    }
  }
}

.last-way-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  margin: 0 15px;
  margin-top: 75px;
  cursor: pointer;

  &.pavedRoad {
    &:before {
      background-color: #A7CEFB;
    }
  }
  
  &:before {
    content: "";
    width: 50px;
    height: 40px;
    background-color: #d0d3e3;
    position: absolute;
    left: 50%;
    top: -57px;
    z-index: 1;
    transform: translateX(-50%);
  }

  img {
    width: 50px;
    transform: rotate(180deg);
    margin-top: 10px;
  }
}

.last-ways {
  display: flex;
  justify-content: center;
  padding-bottom: 150px;
}

.decision-tree {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.tree-wrapper {
  max-width: 100%;
  overflow: auto;
  padding: 60px;
}
.showBanner {
  .legend {
    top: 130px;
  }
}

.legend {
  position: absolute;
  left: 80px;
  right: 80px;
  top: 80px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      margin: 0 10px;


      svg {
        margin-right: 10px;
      }
    }
    .triangle {
      display: block;
      width: 0;
      height: 0;
      border-top: 23px solid transparent;
      border-bottom: 23px solid transparent;
      border-left: 23px solid red;
    }
    .round {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 11px;
      margin-left: 4px;

      &.paved {
        border: 2px solid #7db3ef;
      }
      &.service {
        border: 2px solid #56c88a;
      }
    }
  }
}
</style>